import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DropdownMenu from '@virtus/components/DropdownMenu';
import DateRangeBox from 'devextreme-react/date-range-box';
import { locale } from 'devextreme/localization';
import * as S from './date-filter-selection.style';

const msInDay = 1000 * 60 * 60 * 24;

const convertRangeToDays = ([startDate, endDate]: [Date, Date]) => {
  const diffInDay = Math.floor(Math.abs((endDate.valueOf() - startDate.valueOf()) / msInDay));
  return diffInDay;
};

export const DateFilterSelection = () => {
  const [value, setValue] = React.useState('range');
  const [anchorEl, setAnchorEl] = useState<Element | ((el: Element) => Element) | null>(null);
  const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate() - 31));
  const [endDate, setEndDate] = useState(new Date().getTime());
  const [selectedDays, setSelectedDays] = useState(convertRangeToDays([new Date(startDate), new Date(endDate)]));

  useEffect(() => {
    locale(navigator.language);
  }, []);

  const handleDatePickerSelection = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateSelectionMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const radioControl = (
    <Radio
      size="small"
      sx={{
        '&.Mui-checked': {
          color: 'var(--background-snackbar-header-success)',
        },
      }}
    />
  );

  const dateRadioGroup = (
    <S.DateRadioGroupWrapper>
      <FormControl>
        <RadioGroup
          aria-labelledby="date-filter-radio-group"
          name="date-filter-radio-group"
          value={value}
          onChange={handleDateSelectionMode}
          row
        >
          <FormControlLabel value="single" control={radioControl} label="Single" />
          <FormControlLabel value="range" control={radioControl} label="Range" />
        </RadioGroup>
      </FormControl>
    </S.DateRadioGroupWrapper>
  );

  const handleClose = () => setAnchorEl(null);

  const onCurrentValueChange = ({ value: [startDate, endDate] }: any) => {
    let daysCount = 0;
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      daysCount = convertRangeToDays([new Date(startDate), new Date(endDate)]);
      setSelectedDays(daysCount);
    }
  };

  const handleRangeDateSelection = () => {
    // TODO: refresh the views based on date filter selection
    console.info(startDate, '   ', endDate);
    setAnchorEl(null);
  };

  const displayRangeValues = value === 'range' && startDate && endDate && (
    <>
      <FormLabel>Start date: {new Date(startDate).toLocaleDateString(navigator.language)}</FormLabel>
      <FormLabel>End date: {new Date(endDate).toLocaleDateString(navigator.language)}</FormLabel>
      <FormLabel>Days difference: {selectedDays}</FormLabel>
    </>
  );

  const rangePicker = (
    <S.DateRangeWrapper>
      <DateRangeBox
        multiView={false}
        showClearButton
        defaultValue={[startDate, endDate]}
        useMaskBehavior
        onValueChanged={onCurrentValueChange}
      />
      <S.LoadDataButton
        data-testid="load-range-data"
        onClick={handleRangeDateSelection}
        disabled={!startDate || !endDate}
      >
        Load Data
      </S.LoadDataButton>
    </S.DateRangeWrapper>
  );

  return (
    <>
      <S.DateFilterSection>
        {displayRangeValues}
        <FormLabel>Load Data</FormLabel>
        <S.DateFilterIcon
          className="dx-icon dx-icon-event"
          onClick={handleDatePickerSelection}
          title="Load data"
        ></S.DateFilterIcon>
      </S.DateFilterSection>
      <DropdownMenu
        hideButton
        onClose={handleClose}
        open={!!anchorEl}
        customAnchorEl={anchorEl}
        keepMenuOpen
        id="date-range-filter-dropdown"
      >
        {dateRadioGroup}
        {value === 'range' && rangePicker}
      </DropdownMenu>
    </>
  );
};
