import { debounce } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { lighten } from 'polished';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import LoadingIcon, { LoadingIconSizes, LoadingIconType } from 'src/LoadingIcon/LoadingIcon';
import MoreViewsSelector from 'src/ViewToolbar/components/MoreViewsSelector';
import ViewLabel from 'src/ViewToolbar/components/ViewLabel';
import { ViewToolbarView } from 'src/ViewToolbar/ViewToolbar.model';
import styled, { css } from 'styled-components';

const parseViews = (views: ViewToolbarView[]): ViewToolbarView[] =>
  views.map((view: ViewToolbarView) => {
    const { id } = view;
    const { name = '', isBeingCreated: loading = false } = get(view, 'viewSetting.value', {}) as any;
    return { id, name, loading };
  });

interface ViewLabelsProps {
  views: any[];
  onClick: (id: number) => React.MouseEventHandler;
  active?: number;
  reporting?: any;
  onCloseTab?: (id: any, isReport?: boolean) => void;
  parentRef?: React.Ref<any>;
  labelStyle?: any;
  setParentWidth?: (width: number) => string;
  customParseViews?: (data: any[]) => any[];
  isInspectorCollapsed?: boolean;
  isColumnManagerVisible?: boolean;
  isSearchInspectorVisible?: boolean;
  isLayoutManagerVisible?: boolean;
}

const AVG_CHAR_WIDTH = 8; // Assuming for font-family Nunito Sans, the average width of one char as 8px
const PADDING_LEFT_RIGHT = 12;
const MARGIN_RIGHT = 5;
const WIDTH_OTHER_CONTENT = 114;
const WIDTH_INSPECTOR = 470;

const ViewLabels: React.FunctionComponent<ViewLabelsProps> = ({
  views = [],
  onClick,
  active,
  reporting,
  onCloseTab,
  labelStyle,
  customParseViews,
  isInspectorCollapsed,
  isColumnManagerVisible,
  isSearchInspectorVisible,
  isLayoutManagerVisible,
}) => {
  const labelViews = useMemo(() => (customParseViews ? customParseViews(views) : parseViews(views)), [
    views,
    customParseViews,
  ]);
  const reportTabs =
    (reporting &&
      reporting.adHocReports &&
      Object.keys(reporting.adHocReports).reduce((prev: any, key: any) => {
        const report = reporting.adHocReports[key];
        if (!report.error) {
          return [
            ...prev,
            {
              id: key,
              name: report && report.reportLabel,
              isReport: true,
              loading: (report && report.loading) || false,
            },
          ];
        }
        return prev;
      }, [])) ||
    [];

  const [visibleViews, setVisibleViews] = useState<number[]>(
    [...labelViews, ...reportTabs].map((view: ViewToolbarView) => view.id),
  );

  const viewsToDropdown: ViewToolbarView[] = [...labelViews, ...reportTabs].filter(
    (view: ViewToolbarView) => !visibleViews.includes(view.id),
  );

  const getTotalViews = () => [...labelViews, ...reportTabs];

  useLayoutEffect(() => {
    const observer = new ResizeObserver((_event: any) => {
      handleResize();
    });
    observer.observe(document.body);
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInspectorCollapsed, isColumnManagerVisible, isSearchInspectorVisible, isLayoutManagerVisible]);

  const calculateTotalAvailableWidth = (
    isInspectorCollapsed: boolean | undefined,
    isColumnManagerVisible: boolean | undefined,
    isSearchInspectorVisible: boolean | undefined,
    isLayoutManagerVisible: boolean | undefined,
  ) => {
    let totalAvailableWidth = window.innerWidth - WIDTH_OTHER_CONTENT;
    if (isInspectorCollapsed === false) {
      totalAvailableWidth = totalAvailableWidth - WIDTH_INSPECTOR;
    }
    if (isColumnManagerVisible === true) {
      totalAvailableWidth = totalAvailableWidth - WIDTH_INSPECTOR;
    }
    if (isSearchInspectorVisible === true) {
      totalAvailableWidth = totalAvailableWidth - WIDTH_INSPECTOR;
    }
    if (isLayoutManagerVisible === true) {
      totalAvailableWidth = totalAvailableWidth - WIDTH_INSPECTOR;
    }
    return totalAvailableWidth;
  };

  const handleResize = debounce(() => {
    const totalAvailableWidth = calculateTotalAvailableWidth(
      isInspectorCollapsed,
      isColumnManagerVisible,
      isSearchInspectorVisible,
      isLayoutManagerVisible,
    );
    let tabsTotalWidth = 0;
    const categories: number[] = [];
    getTotalViews().forEach(item => {
      /** Tab width depends on certain css rules like font-size, font-family, font-style, letter-spacing etc */
      const subCategoryWidth = item.name.length * AVG_CHAR_WIDTH + PADDING_LEFT_RIGHT + MARGIN_RIGHT;
      tabsTotalWidth += subCategoryWidth;
      if (tabsTotalWidth <= totalAvailableWidth) {
        categories.push(item.id);
      }
    });
    setVisibleViews([...categories]);
  });

  const activeTab = (reporting && reporting.currentReportView) || active;

  return (
    <>
      {[...labelViews, ...reportTabs].map(
        (view: ViewToolbarView) =>
          visibleViews.includes(view.id) && (
            <ViewLabel
              key={view.id}
              view={view}
              onViewClick={onClick}
              active={activeTab}
              onClose={onCloseTab}
              labelStyle={labelStyle}
            />
          ),
      )}
      {!isEmpty(viewsToDropdown) && (
        <MoreViewsSelector
          views={viewsToDropdown}
          activeView={activeTab}
          onViewClick={onClick}
          onClose={onCloseTab}
          labelStyle={labelStyle}
        />
      )}
    </>
  );
};

export const LoadingSpinner = () => <LoadingIcon show type={LoadingIconType.Virtus} size={LoadingIconSizes.small} />;

const LoadingPlaceholder: React.FunctionComponent = () => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    Creating...
    <LoadingSpinner />
  </div>
);

export const LoadingPlaceholderStyled = styled(LoadingPlaceholder)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.div<{ active: boolean; isReport?: boolean; ['data-testid']: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => {
    return props.active
      ? props.isReport
        ? 'var(--primary)'
        : 'var(--background-sub-tab-active)'
      : 'var(--background-sub-tab-active)';
  }};
  color: ${props => {
    return props.active ? 'var(--foreground-sub-tab-active)' : 'var(--foreground-sub-tab)';
  }};
  border-bottom: ${props => (props.active ? 'var(--foreground-sub-tab-active-border-bottom)' : 'none')};
  height: 32px;
  width: 'auto';
  max-width: fit-content;
  padding: ${props => (props['data-testid'] === 'page-view-undefined' ? '0' : '6px')};
  font-size: var(--labelFontSize);
  font-weight: ${props => (props.active ? '600' : 'regular')};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  white-space: nowrap;
  cursor: pointer;
  margin: ${props => (props['data-testid'] === 'page-view-undefined' ? '0' : '4px 5px 0px 0')};
  transition: 0.1s all;

  &:hover {
    ${_props => labelMixin}
  }
`;

const labelMixin = css<any>`
  background-color: ${props => {
    return props.active
      ? props.isReport
        ? lighten(0.1, 'var(--background-sub-tab-active-isReport-hover)')
        : 'var(--background-sub-tab-active-hover)'
      : 'var(--background-sub-tab-active-hover)';
  }};
`;

export const EllipsisText = styled.span<{ maxWidth?: string }>`
  max-width: ${({ maxWidth = '30ch' }) => maxWidth};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ViewLabels;
