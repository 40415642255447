import React from 'react';
import { FormElementInputComponent, FormFieldType } from 'src/components/forms/form-elements';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import { applyMultiplier } from './DefaultMultiplier';
import { getNumeralFormat } from 'src/utils/formatters';
import numeral from 'numeral';

//import { FieldRule } from 'src/components/forms/form-elements/FormElement.model';

const allowedKeys = ['Delete', 'Backspace', '.', 'ctrl+v', 'ctrl+c', 'ctrl+x', 'k', 'm', 'b'];

const convertValueToLocale = (value: any) => value.toLocaleString(navigator.language);

const isPercentageFieldEdited = (field: FormFieldType) =>
  //field.format && field.format.includes('p') && !field.isEditing && (!field.isEdited || field.readonly);
  field.format && field.format.includes('p') && !field.isEditing && !field.isEdited;
export const DecimalInput = ({
  field,
  value = '',
  onChange,
  error,
  style,
  hideLabel,
  isInATable,
  //formValues,
  isEditing,
}: FormElementInputComponent & { isInATable?: boolean; isEditing?: boolean }) => {
  const [hasFocus, setFocus] = React.useState(false);
  const [newInputValue, setNewInputValue] = React.useState<string>('');
  if (field?.hidden) return null;
  //commenting this as we have removed the 'Value' ruleType condition for % fields
  //const fieldRules = field.fieldRules;

  const OnkeyDownHandler = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() == 'v') {
      setNewInputValue('ctrl+v');
    } else if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() == 'c') {
      setNewInputValue('ctrl+c');
    } else if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() == 'x') {
      setNewInputValue('ctrl+x');
    } else {
      setNewInputValue(e.key);
    }
  };

  // It's always better to work directly with glide objects rather
  const isMltiplierFieldEdited = (field: FormFieldType) => field.format && field.fdddisplay && !field.isEdited;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //  it stop user to enter char apart from 'Backspace', '.', 'Delete' or digit
    if (!allowedKeys.some(item => item.toLowerCase() == newInputValue.toLowerCase()) && !/^\d+$/.test(newInputValue)) {
      e.preventDefault();
      return;
    }
    const multipliedValue = applyMultiplier(e.target.value);
    e.target.value = convertValueToLocale(multipliedValue);
    if (onChange) {
      onChange(e);
    }
    field.isEdited = true;
  };
  let val;
  const isValidValue = value || value === 0;
  if ((field.readonly || field.disabled) && isValidValue) {
    if (field.format && isPercentageFieldEdited(field)) {
      val = (value * 100).toFixed(+field.format?.charAt(field.format.length - 1)) + '%';
    } else if (isMltiplierFieldEdited(field) && field.fdddisplay && field.format) {
      const formatVal = getNumeralFormat(field.format);
      val = numeral(value * parseInt(field.fdddisplay)).format(formatVal);
    } else {
      /*  First condition is for the datatype long where the fields do not require comma.
          max & min fraction digits are for displaying value with 2 decimal places & with comma */
      val =
        field.dataType !== 'decimal'
          ? value
          : (field.format &&
              Number(value).toLocaleString(navigator.language, {
                maximumFractionDigits: +field.format?.charAt(field.format.length - 1),
                minimumFractionDigits: +field.format?.charAt(field.format.length - 1),
              })) ||
            Number(value).toLocaleString(navigator.language);
    }
  } else {
    if (
      field.format &&
      isPercentageFieldEdited(field)
      //commenting this condition as except Current Index every dummy field has Value ruletype
      //&& !fieldRules?.find((fieldRule: FieldRule) => fieldRule.ruleType === 'Value')
    ) {
      val = (value * 100).toFixed(+field.format?.charAt(field.format.length - 1));
    } else if (isMltiplierFieldEdited(field) && field.fdddisplay && isValidValue) {
      val = value * parseInt(field.fdddisplay);
    } else {
      val = isValidValue ? value : '';
    }
  }

  const displayValue = typeof value === 'number' ? value.toLocaleString(navigator.language) : val;

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      key={field.name}
      disabled={field.disabled}
      required={field.required}
      readonly={field.readonly}
      hideLabel={hideLabel}
      disableErrorWarning={field.disableErrorWarning}
    >
      <StyledInput
        autoComplete="off"
        data-testid={`${field.name}-decimal` || 'decimal'}
        disabled={field.disabled || field.readonly}
        error={Boolean(error) && !(field.readonly || field.disabled)}
        onChange={handleOnChange}
        onKeyDown={OnkeyDownHandler}
        inputProps={{
          style: {
            textAlign: isInATable ? 'right' : 'left',
          },
        }}
        style={style}
        value={displayValue}
        name={field.name}
        placeholder={!value && value !== 0 && !isEditing ? '' : field.displayName || '0'}
        editIsEnabled={!field.disabled && !field.readonly}
        hasFocus={hasFocus}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </FormErrorWrapper>
  );
};
