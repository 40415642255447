import { authSelectors } from '@virtus/common/auth/reducer';
import { requestAsync } from 'redux-query';
import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { parseDisplayViewData } from 'src/api/queries/display-view';
import { glideQuery } from 'src/api/query';
import {
  MODAL_INSPECTOR,
  selectModalInspectorData,
} from 'src/components/inspectors/glide-object-inspector/modal-inspector';
import {
  ComponentPropsType,
  selectComponents,
  updateComponentAction,
  updateComponentViewAction,
} from 'src/reducers/components';
import { resetModalForm, setModalForm } from 'src/reducers/modalForm.reducer';

export enum GlideViewActions {
  INIT_MODAL_INSPECTOR = 'INIT_MODAL_INSPECTOR',
  AUDIT_TRAIL = 'AUDIT_TRAIL',
}

export function* onClickGlobalModal(action: any): any {
  const { instanceUri } = action.payload;
  yield put(resetModalForm(instanceUri));
  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/glide/display-view/groups',
        body: {
          uri: instanceUri,
          fetch_options: 'workflow_transitions',
          expand_prop: 'actions',
        },
        transform: (body: any) => ({ [MODAL_INSPECTOR]: parseDisplayViewData(body) }),
        update: {
          [MODAL_INSPECTOR]: (prev: any, next: any) => ({
            ...prev,
            [instanceUri]: {
              ...next,
            },
          }),
        },
      }),
    ),
  );
  yield put(updateComponentViewAction('currentObjectInView', instanceUri, { uri: instanceUri }));
  const modalInspectorData = yield select(selectModalInspectorData);
  yield put(setModalForm(modalInspectorData[instanceUri]));
  yield put(updateComponentAction(MODAL_INSPECTOR, { [instanceUri]: { isVisible: true } }));
}

export function* onInspectorRelease(): any {
  const components: ComponentPropsType = yield select(selectComponents);
  const checkTargetUri = components.actionForm?.target_uri;
  if (checkTargetUri) {
    const getResponse = yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/glide/gget',
          options: { method: 'GET' },
          body: { uri: components.actionForm.target_uri },
        }),
      ),
    );
    // this keyActions is for locking inspector while editing.
    const keyAction = 'canceledit';
    const date = new Date().toJSON();
    const checkStatus = getResponse?.body[0]?.data?.status;
    const glideSession = yield select(authSelectors.glideSession);

    if (checkStatus?.includes('Locked for editing by ' + glideSession.username)) {
      yield putResolve(
        requestAsync(
          glideQuery({
            endpoint: '/gset',
            options: { method: 'POST' },
            body: { uri: components.actionForm.target_uri, action: keyAction, date: date },
          }),
        ),
      );
    }
  }
}

export function* onAuditClick(): any {
  const components: ComponentPropsType = yield select(selectComponents);
  const instanceUri = components.actionForm?.target_uri;

  if (instanceUri) {
    const prod = yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/gaudit',
          options: { method: 'GET' },
          body: { uri: instanceUri },
          queryKey: 'audit_trail',
        }),
      ),
    );
    yield put(
      updateComponentAction('auditTrail', {
        data: JSON.parse(prod.body.data),
        schema: prod.body.schema,
        visible: true,
        title: prod.body.display_name,
      }),
    );
  }
}
export const globalModalClick = (payloadArguments: any) => ({
  type: GlideViewActions.INIT_MODAL_INSPECTOR,
  payload: payloadArguments,
});
export const auditClick = () => ({
  type: GlideViewActions.AUDIT_TRAIL,
});
export function* watchModalCellClick() {
  yield takeLatest<any>(GlideViewActions.INIT_MODAL_INSPECTOR, onClickGlobalModal);
}
export function* watchAuditClick() {
  yield takeLatest<any>(GlideViewActions.AUDIT_TRAIL, onAuditClick);
}

export default [watchModalCellClick, watchAuditClick];
