import { RouteComponentProps } from '@reach/router';
import { Page as Screen } from '@virtus/components/page';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'src/reducers';
import { ComponentProps, selectAuditTrail, selectComponents, selectViewComponent } from 'src/reducers/components';
import { activeTabSelector, subRoutesSelector, TabsAction, viewsSelector } from 'src/reducers/tabs';
import GlideToolbar, { GlideToolbarProps } from './components/glide-toolbar/glide-toolbar';
import Notifications from './components/Notifications/Notifications';
import Search from './components/Search';
import MarketmapAuthenticationForm from 'src/components/grids/dxgrid-client-view/templates/marketmap-authentication-form/marketmap-authentication-form';
import { GlideBody } from './screen-wrapper.style';
import { useMarketmap } from 'src/hooks/use-marketmap';
import { dispatchActions } from 'src/app/store';
import NotificationOverlayMessages from 'src/components/screen-wrapper/components/overlay-notifications/overlay-notifications';
import { CONFIRMATION_DIALOG, ImportInstrumentsOverlayTitle } from 'src/utils/constants';
import { notificationSelector } from 'src/reducers/notifications';
import { GlobalOrderPopup } from 'src/components/portfolio/components/global-order/global-order-popup';
import AddInstrumentOverlay from 'src/components/portfolio/components/add-instrument/add-instrument';
import LayoutManagerInspectorComponent from '../inspectors/layout-manager-inspector/layout-manager-inspector';
import { ClientView } from 'src/api/views/client-view';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { glideInspectorStyle } from 'src/components/inspectors/glide-inspector/glide-inspector';
import { PaperStyled } from 'src/components/inspectors/glide-inspector/glide-inspector.style';
import ViewInspector from 'src/components/inspectors/glide-object-inspector/view-inspector';
import { ColumnManager } from 'src/components/column-manager/column-manager';
import { selectCurrentForm } from 'src/reducers/inspectorForm.reducer';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import CreditDetailInspector from '../inspectors/glide-object-inspector/credit-detail-inspector';
import { isCommentaryDirty, selectCommentaryModal } from 'src/sagas/commentary/Commentary.saga';
import CommentaryDialog from '../glide-object-manager/container/CommentaryDialog';
import AuditDialog from '../audit-trail/audit-trail';
export type RenderProps = { notification: Notification | null };

interface OwnProps {
  pageProps: any;
  children?: any;
  clientViewData?: ClientView;
  dataRef: any;
  // Obsolete
  collapseWrapper?: boolean;
  render?: (props?: RenderProps) => JSX.Element;
  pageInspector?: (props?: any) => JSX.Element | null;
  isExpanded?: boolean;
  path?: string;
  subRoutes?: any;
  onClickTab?: (uri: string, path: string) => void;
  setInstanceUri: any;
}

interface NotificationProps {
  notification: Notification | null;
}

interface ReduxProps {
  views: { [viewKey: string]: any };
  clientViewUri: string;
  bottomPanelClientViewUri: string;
  subRoutes: any;
  notification: any;
  clientUri?: string;
  dataSource?: any;
  // TODO: Fix scope creep. Relative props have irrelevant in screen where only static components are!
  // relativeInspector: any;
  // relativeColumnManager: any;
  currentInspectorForm: any;
  commentaryData: any;
  isCommentaryDirty: any;
}

interface NotificationDispatchProps {
  closeMessageModal: () => void;
}

export type ScreenWrapperProps = RouteComponentProps &
  OwnProps &
  Partial<GlideToolbarProps> &
  ComponentProps &
  ReduxProps &
  NotificationProps &
  NotificationDispatchProps;

const onClickTabDefault = () => console.info('Action not defined');

export const ScreenWrapper: FunctionComponent<ScreenWrapperProps> = ({
  children,
  onClickTab = onClickTabDefault,
  dataRef,
  // pageInspector,
  views = { '': '' },
  clientViewUri,
  bottomPanelClientViewUri,
  pageProps,
  pageToolBarProps,
  components,
  links,
  path,
  subRoutes,
  notification,
  closeMessageModal,
  currentInspectorForm,
  commentaryData,
  setInstanceUri,
  isCommentaryDirty,
  dataSource,
}) => {
  const {
    isLoggedInToMarketmap,
    showMMAuthenticationForm,
    isMarketmapConnectedToServer,
  } = components.livePriceToggleState;
  const { loggedInError, turnOffLivePriceButton, setMarketmapCredentials } = useMarketmap();
  const toggleSearchInspector = () => dispatchActions.components.toggleDisplay('searchInspector');
  const toggleLayoutInspector = () => {
    dispatchActions.components.toggleDisplay('layoutManager');
    dispatchActions.components.updateView('gridLayout', clientViewUri, {
      filteredLayouts: null,
    });
  };
  const onTabClick = (tab: any) => {
    if (currentInspectorForm?.isFormDirty) {
      displayConfirmationDialog(tab);
    } else {
      closeInspectorDialog(tab);
    }
  };

  const viewComponents = components.viewComponents[clientViewUri];
  const viewBottomPanelComponent = components.viewComponents[bottomPanelClientViewUri];

  const closeInspectorDialog = (tab: any) => {
    const matchedRoute = subRoutes.find((subRoute: any) => subRoute?.path?.replace('/', '') === tab);
    onClickTab && onClickTab(matchedRoute?.uri, path as string);
  };

  const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  const closeInstrumentModal = () =>
    dispatchActions.components.update('global', { importInstrumentOverlay: { visible: false } });
  // @ts-ignore
  return (
    <>
      <QuitInspector />
      <Screen {...pageProps}>
        {/*modal components*/}
        <MarketmapAuthenticationForm
          displayDialog={showMMAuthenticationForm && isLoggedInToMarketmap ? false : showMMAuthenticationForm}
          onLivePriceTurnOff={turnOffLivePriceButton}
          setMarketmapCredentials={setMarketmapCredentials}
          loggedInError={loggedInError}
          isMarketmapConnectedToServer={isMarketmapConnectedToServer}
        />
        <AddInstrumentOverlay
          displayOverlay={components.global.importInstrumentOverlay.visible}
          modalCloseHandler={closeInstrumentModal}
        />

        <GlobalOrderPopup />
        {notification && notification?.messageList && notification?.showMessagesOnPopup && (
          <NotificationOverlayMessages
            show={notification?.showMessagesOnPopup}
            notification={notification}
            title={ImportInstrumentsOverlayTitle}
            primaryButton={{ text: 'Ok', action: closeMessageModal }}
            onCloseCallback={closeMessageModal}
          />
        )}
        {/*Glide views*/}
        <GlideBody>
          {!components?.summaryPanel?.visible && (
            <GlideToolbar
              pageToolBarProps={pageToolBarProps}
              views={views}
              links={links}
              activeViewKey={clientViewUri}
              onClickTab={onTabClick}
            />
          )}
          {children}
        </GlideBody>
        {components.searchInspector.visible && <Search onClose={toggleSearchInspector} />}
        {components.layoutManager.visible && (
          <LayoutManagerInspectorComponent
            clientViewUri={dataRef?.current?.props?.id ?? clientViewUri}
            loadingIcon={{ type: LoadingIconType.Glide, size: LoadingIconSizes.extraLarge }}
            style={glideInspectorStyle}
            paperProps={{ component: PaperStyled }}
            onClose={toggleLayoutInspector}
          />
        )}
        {/* render global action selector here */}
        {components?.globalActions?.visible}
        {viewBottomPanelComponent?.columnManagerInspector?.visible && (
          <ColumnManager gridLayout={viewBottomPanelComponent.gridLayout} clientUri={bottomPanelClientViewUri} />
        )}
        {viewComponents?.columnManagerInspector?.visible && (
          <ColumnManager gridLayout={viewComponents.gridLayout} clientUri={clientViewUri} />
        )}
        {/* Plug and play components :) */}
        <ViewInspector />
        <CreditDetailInspector />
        <Notifications setInstanceUri={setInstanceUri} />
        <CommentaryDialog commentaryData={commentaryData} isCommentaryDirty={isCommentaryDirty} />
        <AuditDialog dataSource={dataSource} components={components} />
      </Screen>
    </>
  );
};

const mapStateToProps = (state: RootState, { path }: any): ComponentProps & ReduxProps => {
  const bottomPanelComponent = selectViewComponent(state, 'bottomPanel', activeTabSelector(state));
  return {
    components: selectComponents(state),
    views: viewsSelector(state, path),
    subRoutes: subRoutesSelector(state, path),
    clientViewUri: activeTabSelector(state),
    bottomPanelClientViewUri: bottomPanelComponent?.clientViewUri,
    notification: notificationSelector(state),
    currentInspectorForm: selectCurrentForm(state),
    commentaryData: selectCommentaryModal(state),
    dataSource: selectAuditTrail(state),
    isCommentaryDirty: isCommentaryDirty(state),
  };
};

const mapDispatchToProps = (dispatch: any): NotificationDispatchProps | any => ({
  onClickTab: (viewUri: string, path: string) =>
    dispatch({ type: TabsAction.CHANGE_VIEW, data: { fullPath: path, activeView: viewUri } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenWrapper);
