import ClickAwayListener from '@mui/material/ClickAwayListener';
import Apps from '@mui/icons-material/Apps';
import React, { useState, ComponentType } from 'react';
import { GenesisIcon, GlideIcon, NexusIcon } from 'src/icons';
import styled from 'styled-components';

export interface AppLink {
  name: AppNames;
  route: string;
  active?: number;
  color: string;
}

export enum AppNames {
  nexus = 'nexus',
  glide = 'glide',
  genesis = 'genesis',
}

export interface Props {
  value: string;
  enabledApps: AppNames[];
  appMenuLinks?: AppLink[];
}

export interface State {
  visible: boolean;
}

const SNexusIcon = styled(NexusIcon)`
  display: flex;
  flex: 1;
  width: 22px;
  margin-bottom: 5px;
`;

const SGlideIcon = styled(GlideIcon)`
  display: flex;
  flex: 1;
  width: 22px;
  margin-bottom: 5px;
`;

const SGenesisIcon = styled(GenesisIcon)`
  display: flex;
  flex: 1;
  width: 22px;
  margin-bottom: 5px;
`;

const icons: { [key in AppNames]: ComponentType } = {
  nexus: SNexusIcon,
  glide: SGlideIcon,
  genesis: SGenesisIcon,
};

const AppMenu: React.SFC<Props> = ({ enabledApps, value, appMenuLinks = [] }) => {
  const [visible, setVisible]: any = useState(false);
  const [hovering, setHovering] = useState<number>(-1);
  const open = () => setVisible(true);
  const close = (_e: any) => setVisible(false);

  const links = appMenuLinks.filter(appMenuLink => enabledApps.includes(appMenuLink.name));

  return (
    <ClickAwayListener onClickAway={close}>
      <>
        <Container onClick={visible ? close : open}>
          <AppsIcon />
          <AbsoluteMenu visible={visible}>
            <MenuPointer />

            <Menu>
              {links.map((appLink: AppLink, index: number) => {
                const Icon = icons[appLink.name];
                const isSelected = appLink.name.toLowerCase() === value.toLowerCase();

                return (
                  <MenuItem
                    key={appLink.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={appLink.route}
                    selected={isSelected}
                    onMouseEnter={() => setHovering(index)}
                    onMouseLeave={() => setHovering(-1)}
                    style={{
                      background: isSelected
                        ? appLink.color
                        : index === hovering
                        ? 'var(--background-apps-menu-item-hover)'
                        : 'none',
                    }}
                  >
                    <Icon />
                    <ItemText>{appLink.name}</ItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </AbsoluteMenu>
        </Container>
      </>
    </ClickAwayListener>
  );
};

const Container: any = styled.div`
  position: relative;
  left: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  color: var(--foreground-apps-icon);
  padding: 4px;
  border-radius: 50%;
  &:hover {
    background-color: var(--background-apps-icon-hover);
  }
`;

const Menu: any = styled.div`
  display: flex;
  width: 140px;
  padding: 5px;
  flex-wrap: wrap;
  border-radius: 3px;
  background: var(--background-apps-menu);
  box-shadow: 0 2px 4px 0 var(--foreground-apps-menu-box-border);
`;

const AbsoluteMenu: any = styled.div`
  visibility: ${(props: any) => (props.visible ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 40px;
  padding: 5px;
  z-index: 501;
`;

const MenuPointer: any = styled.div`
  width: 0px;
  height: 0px;
  margin-top: 32px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--background-apps-menu);
`;

const MenuItem: any = styled.a<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  transition: background 0.2s;
  text-decoration: none;
  color: ${props => {
    return props.selected ? 'var(--foreground-apps-menu-item-selected)' : 'var(--foreground-apps-menu-item)';
  }};
`;

const ItemText = styled.h5`
  text-transform: capitalize;
`;

const AppsIcon: any = styled(Apps)`
  color: var(--foreground-apps-icon);
  font-size: var(--apps-icon-font-size);
`;

export default AppMenu;
