import React, { useEffect } from 'react';
import { useRequest } from 'redux-query-react';
import { GlideCommentaryApi } from 'src/api/queries';
import GlideListContainerComponent from 'src/components/glide-list-container/glide-list-container-component';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { Header } from 'src/components/glide-object-manager/components/header/header';
import { CreditDetailsSagaAction } from 'src/sagas/credit/credit.saga';
import { connect, useDispatch } from 'react-redux';
import { HeaderWrapper, Body } from './glide-object-commentary-container-style';
import { getInstanceUri, selectCommentaryModal } from 'src/sagas/commentary/Commentary.saga';
import { RootState } from 'src/reducers';
import { compose } from 'redux';

export type GlideObjectCommentaryContainerProps = {
  commentaryUris?: string[];
  creditUri?: string;
  instanceUri?: string;
  componentType?: string;
  showHeader?: boolean;
  fromColumn?: boolean;
  onBack: (event?: any, reason?: any) => void;
  handleExpansion?: () => void;
  isOpenFromGrid?: boolean;
};

export interface ReduxProps {
  commentaryData?: any;
  isCommentaryLoading?: boolean;
  instanceUri?: any;
}

export const GlideObjectCommentaryContainer = ({
  commentaryUris,
  creditUri,
  instanceUri,
  onBack,
  handleExpansion,
  componentType = '',
  showHeader = true,
  fromColumn = false,
  isOpenFromGrid = false,
  commentaryData,
  isCommentaryLoading = false,
}: GlideObjectCommentaryContainerProps & ReduxProps) => {
  const dispatch = useDispatch();
  const _commentaryUris = isOpenFromGrid ? commentaryData.commentaryUris : commentaryUris;
  const _componentType = isOpenFromGrid ? commentaryData.fieldName : componentType;
  const _creditUri = isOpenFromGrid ? commentaryData.creditUri.uri : creditUri;
  const _instanceUri = isOpenFromGrid ? commentaryData.instanceUri : instanceUri;

  const [{ isPending }] = useRequest(
    GlideCommentaryApi.getGlideCommentaryData({ uri: _commentaryUris, type: _componentType }),
  );

  useEffect(() => {
    if (fromColumn) {
      dispatch({ type: CreditDetailsSagaAction.FETCH_CREDIT_URI_FOR_COMMENTARY, payload: { uri: _instanceUri } });
    }
  }, []);

  return isPending || isCommentaryLoading ? (
    <Loading
      type={LoadingIconType.Glide}
      size={LoadingIconSizes.extraLarge}
      style={{ backgroundColor: 'var(--background-app)' }}
      show
      full
    />
  ) : (
    <HeaderWrapper data-testid="glide-commentary-object-manager">
      <Body>
        {showHeader && <Header onBack={onBack} title={_componentType} handleExpansion={handleExpansion} />}
        <GlideListContainerComponent uri={_creditUri} componentType={_componentType} />
      </Body>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  commentaryData: selectCommentaryModal(state),
  isCommentaryLoading: GlideCommentaryApi.isNewCommentaryPending(state),
  instanceUri: getInstanceUri(state),
});

export default compose(connect(mapStateToProps))(GlideObjectCommentaryContainer);
