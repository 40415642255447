import React from 'react';

import {
  CheckboxInput,
  DateInput,
  DecimalInput,
  SearchInput,
  SelectInput,
  TextInput,
  MultipleDateInput,
  MultipleSelectInput,
} from 'src/components/forms/form-elements/form-fields';

import TogglerInput from './form-fields/TogglerInput/TogglerInput';
import { FormFieldType } from 'src/components/forms/form-elements/FormElement.model';

interface FormFieldProps {
  field: FormFieldType;
  onChange: (value: any) => void;
  value: string | boolean | Date;
  error: string;
  style?: React.CSSProperties;
  isInATable?: boolean;
  hideLabel?: boolean;
  //formValues: object;
  customWidth?: string;
  onSearchLink?: () => void;
  isEditing?: boolean;
  activeViewKey?: string;
  formGroupChildrenStyle?: React.CSSProperties;
}

export const FormField = ({
  field,
  onChange,
  value,
  error,
  style,
  isInATable,
  hideLabel,
  //formValues,
  customWidth,
  onSearchLink,
  isEditing,
  activeViewKey,
  formGroupChildrenStyle,
}: FormFieldProps) => {
  const fieldInputs: any = {
    text: TextInput,
    toggler: TogglerInput,
    select: SelectInput,
    multipleselect: MultipleSelectInput,
    number: DecimalInput,
    calendar: DateInput,
    search: SearchInput,
    checkbox: CheckboxInput,
    calendarMultiple: MultipleDateInput,
  };
  // @ts-ignore
  const FieldInput: FormInputs = fieldInputs[field.formElementType];
  if (!FieldInput) {
    console.warn('Unhandled form type: ', field.formElementType);
  }

  return (
    <FieldInput
      error={error}
      field={field}
      onChange={onChange}
      value={value}
      style={style}
      isInATable={isInATable}
      hideLabel={hideLabel}
      //formValues={formValues}
      customWidth={customWidth}
      linkCallback={onSearchLink}
      isEditing={isEditing}
      activeViewKey={activeViewKey}
      formGroupChildrenStyle={formGroupChildrenStyle}
    />
  );
};
