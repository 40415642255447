/* eslint-disable no-confusing-arrow */
import React, { FC } from 'react';
import {
  discardInspectorFormChanges,
  inspectorFormState,
  selectInspectorForm,
} from 'src/reducers/inspectorForm.reducer';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import * as S from 'src/components/inspectors/glide-inspector/glide-inspector-footer.style';
import { CONFIRMATION_DIALOG, CONFIRM_DIALOG, mapGlideObjectBgColor } from 'src/utils/constants';
import { editActions } from 'src/components/inspectors/glide-inspector/edit-actions';
import { RootState } from 'src/reducers';
import { dispatchActions } from 'src/app/store';
import { getActionsGroupedByType } from 'src/utils/action-resolver';
import GlideInspectorActions from './glide-inspector-actions';
import { GlideAction, GlideWorkflowAction } from 'src/reducers/actions';
import {
  closeModalForm,
  discardModalFormChanges,
  modalFormState,
  selectModalForm,
} from 'src/reducers/modalForm.reducer';
import { activeTabSelector } from 'src/reducers/tabs';
import { ModalInspectorReduxDispatchProps } from '../glide-object-inspector/modal-inspector';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { InspectorReduxDispatchProps } from '../glide-object-inspector/view-inspector';
import {
  closeCreditDetailForm,
  creditDetailForm,
  discardCreditDetailFormChanges,
  selectCreditDetailForm,
} from 'src/reducers/creditDetailForm.reducer';
import { CreditDetailInspectorReduxDispatchProps } from '../glide-object-inspector/credit-detail-inspector';

export interface ReduxProps {
  inspectorForm: inspectorFormState;
  modalForm: modalFormState;
  creditDetailForm: creditDetailForm;
  clientViewUri: string;
  canCloseModal?: boolean;
  components: any;
}

export type GlideInspectorFooterProps = {
  actions: GlideAction[]; // string[] is expanded to GlideAction[] with expand_props: action on GGET
  workflowActions: GlideWorkflowAction[];
  disabled?: boolean;
  isExpanded?: boolean;
  onWorkflowActionClicked?: (transition_uri: string) => void;
  isModalInspector?: boolean;
  isViewInspector?: boolean;
  isCreditDetailInspector?: boolean;
  targetInstance?: string;
  viewInspectorData?: any;
  isGlobalViewInspectorFooter?: boolean;
  instanceUri?: string;
};

/**
 * Determines if a modal can be closed based on the provided instance URI and the actions associated with it.
 *
 * This function is crucial for managing the behavior of modals in different modes. When a modal is opened
 * in write mode, it should hide immediately after discarding changes. However, for modals opened in read-only
 * mode, if the user switches to edit mode and then cancels or discards changes, the modal should revert
 * back to read-only mode instead of closing entirely. This ensures a seamless user experience by allowing
 * users to return to the read-only state without losing context.
 */
const canCloseModalByGlobalActionUri = (components: any, instanceUri: string) => {
  if (instanceUri && components.modalInspector[instanceUri]) {
    return ['global_actions', 'instance/actions/'].some(action =>
      components.modalInspector[instanceUri]?.uri?.includes(action),
    );
  }
  return false;
};

export const GlideInspectorFooter: FC<
  GlideInspectorFooterProps &
    ReduxProps &
    ModalInspectorReduxDispatchProps &
    InspectorReduxDispatchProps &
    CreditDetailInspectorReduxDispatchProps
> = ({
  actions,
  workflowActions,
  inspectorForm,
  modalForm,
  creditDetailForm,
  clientViewUri,
  isModalInspector = false,
  isViewInspector = false,
  isCreditDetailInspector = false,
  discardModalFormChanges,
  discardCreditDetailFormChanges,
  closeModalForm,
  closeCreditDetailForm,
  targetInstance,
  discardInspectorFormChanges,
  viewInspectorData,
  isGlobalViewInspectorFooter = false,
  instanceUri,
  components,
}) => {
  const canCloseModal = canCloseModalByGlobalActionUri(components, instanceUri || '');
  if (isEmpty(actions)) return null;
  const { primaryActions, secondaryActions, otherActions } = getActionsGroupedByType(actions);
  const _clientViewUri = instanceUri ? instanceUri : clientViewUri;
  const closeModalInspectorDialog = () => closeModalForm(canCloseModal || false, _clientViewUri);
  const closeInspectorDialog = () => {
    dispatchActions.inspectorForm.setInspectorForm(
      viewInspectorData,
      isGlobalViewInspectorFooter ? 'global' : _clientViewUri,
    );
    // dispatchActions.inspectorForm.setInspectorFormEdit(false, clientViewUri);
  };

  const closeCreditDetailDialog = () => closeCreditDetailForm(false);

  const { DialogComponent: QuitModalInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeModalInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  const closeActionFormDialog = (action: any) => {
    dispatchMenuActions(action);
  };

  const { DialogComponent: QuitActionForm, onDispatcherClick: displayConfirmDialog } = useConfirmationDialog({
    onClick: closeActionFormDialog,
    ...CONFIRM_DIALOG,
  });

  const {
    DialogComponent: QuitInspector,
    onDispatcherClick: displayInspectorConfirmationDialog,
  } = useConfirmationDialog({
    onClick: closeInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  const {
    DialogComponent: QuitCreditDetailInspector,
    onDispatcherClick: displayCreditDetailConfirmationDialog,
  } = useConfirmationDialog({
    onClick: closeCreditDetailDialog,
    ...CONFIRMATION_DIALOG,
  });

  const onCancel = () => {
    if (isModalInspector) {
      canCloseModal
        ? discardModalFormChanges(displayConfirmationDialog, true, _clientViewUri)
        : discardModalFormChanges(displayConfirmationDialog, false, _clientViewUri);
    } else if (isCreditDetailInspector) {
      discardCreditDetailFormChanges(displayCreditDetailConfirmationDialog, false);
    } else {
      discardInspectorFormChanges(displayInspectorConfirmationDialog, false, isGlobalViewInspectorFooter);
    }
  };

  const onSave = () => {
    if (isModalInspector) {
      dispatchActions.modalForm.saveModalForm(instanceUri);
    } else if (isCreditDetailInspector) {
      dispatchActions.creditDetailForm.saveCreditDetailForm();
    } else {
      dispatchActions.inspectorForm.saveInspectorForm(isGlobalViewInspectorFooter ? 'global' : '');
    }
  };

  const renderEditButtons = () => (
    <>
      <S.SecondaryButton
        key={editActions.cancel.uri}
        data-testid="secondary-btn"
        onClick={onCancel}
        backgroundcolor={mapGlideObjectBgColor(editActions.cancel.data?.editor_button_style)}
      >
        {editActions.cancel.data.display_name}
      </S.SecondaryButton>

      <S.PrimaryButton
        key={editActions.save.uri}
        data-testid="primary-btn"
        onClick={onSave}
        backgroundcolor={mapGlideObjectBgColor(editActions.save.data?.editor_button_style)}
      >
        {editActions.save.data.display_name}
      </S.PrimaryButton>
    </>
  );

  let isEdit: boolean | undefined = false;
  if (isModalInspector) {
    isEdit = modalForm?.uri[_clientViewUri]?.isEdit;
  } else if (isCreditDetailInspector) {
    isEdit = creditDetailForm?.isEdit;
  } else {
    isEdit = inspectorForm.uri[isGlobalViewInspectorFooter ? 'global' : _clientViewUri]?.isEdit;
  }
  const dispatchMenuActions = (action: any) => {
    return dispatchActions.execute({
      glideAction: action,
      clientViewUri: isGlobalViewInspectorFooter ? 'global' : _clientViewUri,
    });
  };
  const onClickEvent = (action: any) => {
    if (action?.uri == 'instance/actions/create_new_close_position_order') {
      return displayConfirmDialog(action);
    } else {
      return dispatchMenuActions(action);
    }
  };
  // const isEdit = isModalInspector ? modalForm.isEdit : inspectorForm.uri[clientViewUri]?.isEdit;

  return (
    <>
      {isModalInspector && <QuitModalInspector />}
      {isCreditDetailInspector && <QuitCreditDetailInspector />}
      {!isModalInspector && !isCreditDetailInspector && <QuitInspector />}
      {!isModalInspector && !isCreditDetailInspector && isViewInspector && <QuitActionForm />}
      {isModalInspector && !isCreditDetailInspector && !isViewInspector && <QuitActionForm />}
      <S.Footer data-testid="glide-inspector-footer">
        <GlideInspectorActions
          actions={otherActions}
          workflowActions={workflowActions}
          targetInstance={targetInstance}
          actionButtonText="Actions"
          isModalInspector={isModalInspector}
          isCreditDetailInspector={isCreditDetailInspector}
          isGlobalGlideInspectorActions={isGlobalViewInspectorFooter}
        />
        {isEdit && renderEditButtons()}
        {!isEdit && (
          <>
            {primaryActions.map(action => (
              <S.PrimaryButton
                key={action.uri}
                data-testid="primary-btn"
                onClick={() => {
                  dispatchActions.execute({
                    glideAction: action,
                    clientViewUri: isGlobalViewInspectorFooter ? 'global' : _clientViewUri,
                  });
                }}
                disabled={!action.data.enabled}
                backgroundcolor={mapGlideObjectBgColor(action.data?.editor_button_style)}
              >
                {action.data.display_name}
              </S.PrimaryButton>
            ))}

            {secondaryActions.map(action => (
              <S.SecondaryButton
                key={action.uri}
                data-testid="secondary-btn"
                onClick={() => {
                  onClickEvent(action);
                }}
                disabled={!action.data.enabled}
                backgroundcolor={mapGlideObjectBgColor(action.data?.editor_button_style)}
              >
                {action.data.display_name}
              </S.SecondaryButton>
            ))}
          </>
        )}
      </S.Footer>
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  components: state.components,
  inspectorForm: selectInspectorForm(state),
  modalForm: selectModalForm(state),
  creditDetailForm: selectCreditDetailForm(state),
  clientViewUri: activeTabSelector(state),
});

const mapDispatchToProps = (
  dispatch: any,
): ModalInspectorReduxDispatchProps & InspectorReduxDispatchProps & CreditDetailInspectorReduxDispatchProps => ({
  discardModalFormChanges: (displayConfirmationDialog: Function, hideInspector: boolean, _clientViewUri: string) =>
    dispatch(discardModalFormChanges(displayConfirmationDialog, hideInspector, _clientViewUri)),
  closeModalForm: (hideInspector: boolean, _clientViewUri) => dispatch(closeModalForm(hideInspector, _clientViewUri)),
  discardCreditDetailFormChanges: (displayConfirmationDialog: Function, hideInspector: boolean) =>
    dispatch(discardCreditDetailFormChanges(displayConfirmationDialog, hideInspector)),
  closeCreditDetailForm: (hideInspector: boolean) => dispatch(closeCreditDetailForm(hideInspector)),
  discardInspectorFormChanges: (
    displayConfirmationDialog: Function,
    hideInspector: boolean,
    isGlobalViewInspectorFooter: boolean,
  ) => dispatch(discardInspectorFormChanges(displayConfirmationDialog, hideInspector, isGlobalViewInspectorFooter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlideInspectorFooter);
