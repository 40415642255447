import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as T from 'src/components/typo/typo';
import { RootState } from 'src/reducers';
import { CustomGlideRoute, getRoute, TabsAction, tabSelector, TabState } from 'src/reducers/tabs';
import * as S from './tab-panel-navigation.style';
import { selectCurrentForm } from 'src/reducers/inspectorForm.reducer';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { CONFIRMATION_DIALOG } from 'src/utils/constants';

interface ReduxProps {
  tabs: TabState;
  currentInspectorForm: any;
}

interface ReduxDispatch {
  dispatchOpenNewTab: (path: string) => void;
  dispatchCloseTab: (data: CustomGlideRoute) => void;
  showDashboard: () => void;
}

const TabPanelNavigation: React.FC<ReduxProps & ReduxDispatch> = React.memo(
  ({ tabs, dispatchOpenNewTab, dispatchCloseTab, currentInspectorForm }) => {
    const closeTabHandler = (tab: CustomGlideRoute, e: any) => {
      if (currentInspectorForm?.isFormDirty && tabs.currentPath === tab.fullPath && tabs.moduleTabs.length > 1) {
        e.stopPropagation();
        displayConfirmationDialog({ tab, e });
      } else {
        closeInspectorDialog({ tab, e });
      }
    };

    const routeChangeCallback = (path: string) => {
      if (currentInspectorForm?.isFormDirty && tabs.currentPath !== path) {
        displayConfirmationDialog(path);
      } else {
        closeInspectorDialog(path);
      }
    };

    const closeInspectorDialog = (data: any) => {
      const { tab, e } = data;
      if (e) {
        e.stopPropagation();
        dispatchCloseTab(tab);
      } else if (tabs.currentPath === '/' || tabs.currentPath !== data) {
        dispatchOpenNewTab(data);
      }
    };

    const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
      onClick: closeInspectorDialog,
      ...CONFIRMATION_DIALOG,
    });

    useEffect(() => {
      const pathName = getRoute(tabs.routes, location.pathname) ? location.pathname : '/';
      routeChangeCallback(pathName);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getToolTipFromArray = (tabNamesArray: any) => {
      let toolTipString = '';
      tabNamesArray.forEach(
        (names: string) =>
          (toolTipString = `${toolTipString}${names}
        `), //Adding a new Line after each tab Name in case of multiple tabName
      );

      return toolTipString;
    };

    const renderTabs = (moduletabs: CustomGlideRoute[]) => {
      if (!moduletabs) return;
      return moduletabs.map((tab: CustomGlideRoute, i: number) => {
        if (!tabs.routes || tabs.routes.length === 0 || tab.fullPath === '/') return;
        const matchedRoute = getRoute(tabs.routes, tab.fullPath);
        const routeTabName = matchedRoute?.displayName ?? matchedRoute?.name;
        const tabName =
          moduletabs[i].tabName && Array.isArray(moduletabs[i].tabName) ? moduletabs[i].tabName![0] : routeTabName;
        const tabNameCount = Array.isArray(moduletabs[i].tabName) ? moduletabs[i].tabName!.length : 0;
        const toolTipTitle =
          Array.isArray(moduletabs[i].tabName) && moduletabs[i].tabName!.length > 1
            ? getToolTipFromArray(moduletabs[i].tabName)
            : tabName;
        return (
          <S.StyledTooltip key={i} title={toolTipTitle}>
            <S.Tab
              highlighted={tab.isHighlighted}
              onClick={routeChangeCallback.bind(event, tab.fullPath)}
              data-testid={tabName}
            >
              <T.TitleWithEllipsis style={{ maxWidth: '180px' }}>{tabName}</T.TitleWithEllipsis>
              {tabNameCount > 1 && <span style={{ paddingLeft: '5px' }}>{`+ ${tabNameCount - 1}`}</span>}
              <i
                className="dx-icon dx-icon-close"
                data-testid={`${tabName}-close`}
                onClick={(e: any) => closeTabHandler(tab, e)}
              />
            </S.Tab>
          </S.StyledTooltip>
        );
      });
    };

    return (
      <>
        <QuitInspector />
        <S.GlideHeader>
          {/* <S.DashBoardTab key="dashboard" active={false} onClick={showDashboard} data-testid="dashboard">
            <ChartIcon htmlColor="white" style={{ fontSize: '14px' }} />
          </S.DashBoardTab> */}
          {renderTabs(tabs.moduleTabs)}
        </S.GlideHeader>
      </>
    );
  },
);

const mapStateToProps = (state: RootState): ReduxProps => ({
  tabs: tabSelector(state),
  currentInspectorForm: selectCurrentForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatch => ({
  dispatchOpenNewTab: (path: string) => dispatch({ type: TabsAction.OPEN_TAB, path }),
  dispatchCloseTab: (data: CustomGlideRoute) => dispatch({ type: TabsAction.CLOSE_TAB, data }),
  showDashboard: () => dispatch({ type: TabsAction.SHOW_DASHBOARD }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabPanelNavigation);
