import IconButton from '@mui/material/IconButton';
import { SvgIconProps } from '@mui/material/SvgIcon';
import IconClose from '@mui/icons-material/Close';
import React, { FunctionComponent } from 'react';
import * as S from './SnackBarContent.style';
import { IconError, InfoIcon, SuccessIcon, WarningIcon } from 'src/icons';

export type NotificationType = 'error' | 'info' | 'success' | 'warning';

export type Notification = {
  type: NotificationType;
  title: string;
  message: string;
  messageList?: any;
  showMessagesOnPopup?: boolean;
  actionLinks?: ActionLink[];
  autoHideDuration?: number; // in ms
  payloadData?: any;
};

export type ActionLink = {
  onClick: (e: any) => void;
  title: string;
  instanceUri: string;
};

interface SnackBarContentProps {
  notification: Notification;
  closeNotification: () => void;
  dataTestId?: string;
  setInstanceUri?: any;
}

interface IconNotification {
  [key: string]: React.ComponentType<SvgIconProps>;
}

const iconNotification: IconNotification = {
  success: SuccessIcon,
  info: InfoIcon,
  warning: WarningIcon,
  error: IconError,
};

export const SnackBarContent: FunctionComponent<SnackBarContentProps> = ({
  notification,
  closeNotification,
  dataTestId = 'notification',
  setInstanceUri,
}) => {
  if (!notification.message) return null;

  const NotificationIcon: React.ComponentType<SvgIconProps> = iconNotification[notification.type];
  return (
    <S.SnackbarContent
      message={
        <S.Container data-testid={dataTestId}>
          <S.Header type={notification.type}></S.Header>
          <S.Body type={notification.type}>
            <S.StyledBodyIcon>
              <NotificationIcon />
              <S.Title>{notification.title}</S.Title>
            </S.StyledBodyIcon>
            <S.Message>{notification.message}</S.Message>
            {notification.actionLinks && (
              <ul>
                {notification.actionLinks.map((link: ActionLink) => (
                  <li key={link.title}>
                    <a
                      onClick={e => {
                        if (link.instanceUri) {
                          setInstanceUri(link.instanceUri);
                        }

                        link.onClick(e);
                      }}
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </S.Body>
        </S.Container>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={closeNotification} size="large">
          <IconClose fontSize="small" />
        </IconButton>,
      ]}
    />
  );
};
