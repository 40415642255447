import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import styled from 'styled-components';

export const DropdownButton = styled.div<{ dark?: boolean }>`
  display: flex;
  align-items: center;
  border: ${props => (props.dark ? 'none' : 'var(--foreground-dx-grid-action-drop-down-border)')};
  background: ${props => (props.dark ? 'var(--grey)' : 'var(--white)')};
  color: ${props => (props.dark ? 'var(--white)' : 'var(--foreground-form)')};
  height: 28px;
  font-size: var(--dropdownFontSize);
  border-radius: 2px;
  cursor: pointer;
  margin-left: 5px;
  width: 100%;
`;

export const DropdownButtonText = styled.div<{ showBorder?: boolean; disabled?: boolean }>`
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  border-right: ${props => (props.showBorder ? `1px solid var(--accent-grey)` : 'none')};
  height: 28px;
  width: 100%;
  opacity: ${props => (props.disabled ? `0.5` : ``)};
  cursor: ${props => (props.disabled ? `default` : `pointer`)};
`;

export const ArrowDropDownIcon = styled(ArrowDropDown)`
  height: 26px;
  &:hover {
    background-color: var(--grey-hover);
  }
`;
