import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { requestAsync, updateEntities } from 'redux-query';
import { formattedDate } from './date-input/date-input.saga';
import { selectedFundURIs } from 'src/reducers/portfolio';
import { glideQuery, GlideRequestBody } from 'src/api/query';
import {
  activeTabSelector,
  selectClientViewBottomPanel,
  selectCVC,
  selectInstanceSensitiveBottomPanel,
} from 'src/reducers/tabs';
import { selectViewComponent, updateComponentAction, updateComponentViewAction } from 'src/reducers/components';
import { selectClientViewData } from 'src/api/views/client-view/ClientView';
import {
  ClientViewConfiguration,
  ClientViewConfigurationData,
  ClientViewTypeLookUps,
} from 'src/components/glide-view/glide-view.model';
import { Glide } from 'src/api/queries';
import { dispatchActions } from 'src/app/store';
import { executeAction } from 'src/utils/action-resolver';
type GlideViewAction = 'FETCH_CLIENT_VIEW';
import { VIEW_INSPECTOR } from 'src/components/inspectors/glide-object-inspector/view-inspector';
import { resetInspectorForm } from 'src/reducers/inspectorForm.reducer';
import { NotificationsAction } from 'src/reducers/notifications';
import { parseDisplayViewData } from 'src/api/queries/display-view';
import { resetCreditDetailForm, setCreditDetailForm } from 'src/reducers/creditDetailForm.reducer';
import {
  CREDIT_DETAIL_INSPECTOR,
  selectCreditDetailInspectorData,
} from 'src/components/inspectors/glide-object-inspector/credit-detail-inspector';
import { onInspectorRelease } from './glide-object-handler.saga';

export enum GlideViewActions {
  FETCH_CLIENT_VIEW = 'FETCH_CLIENT_VIEW',
  ON_GRID_CELL_CLICK = 'ON_GRID_CELL_CLICK',
  OPEN_BOTTOM_LINK = 'OPEN_BOTTOM_LINK',
  UPDATE_SCENARIO_RESULT_COLUMN = 'UPDATE_SCENARIO_RESULT_COLUMN',
  OPEN_BOTTOM_PANEL = 'OPEN_BOTTOM_PANEL',
  INIT_INSPECTOR = 'INIT_INSPECTOR',
  OPEN_CREDIT_DETAIL = 'OPEN_CREDIT_DETAIL',
  OPEN_TEST_RESULTS = 'OPEN_TEST_RESULTS',
  INIT_GLOBAL_INSPECTOR = 'INIT_GLOBAL_INSPECTOR',
  INIT_MODAL_INSPECTOR = 'INIT_MODAL_INSPECTOR',
}

export interface FetchClientViewPayload {
  clientViewUri: string;
  isManualRefresh?: boolean;
}

interface ActionPayLoad {
  type: GlideViewAction;
  payload: FetchClientViewPayload;
}

export const fetchClientViewAction = ({ clientViewUri, isManualRefresh }: FetchClientViewPayload): ActionPayLoad => ({
  type: 'FETCH_CLIENT_VIEW',
  payload: { clientViewUri, isManualRefresh },
});

export interface OnGridCellClickPayloadArguments {
  _uri: any;
  instanceUri: string;
  fieldName: string;
  ['Display Name']?: string;
}
export interface OnClickInspectorPayloadArguments {
  _uri: any;
  instanceUri: string;
  fieldName: string;
}
export interface OnClickCompliancePayloadArguments {
  instanceUri: string;
  fieldName?: any;
}
export interface OnGridCellClickPayload {
  data: any;
  payload: OnGridCellClickPayloadArguments;
}
export interface OnClickInspectorPayload {
  data: any;
  payload: OnClickInspectorPayloadArguments;
}
export interface OnClickGlobalInspectorPayload {
  payload: OnClickInspectorPayloadArguments;
}
export interface OnClickComplianceTestPayload {
  data: any;
  payload: OnClickCompliancePayloadArguments;
}
export let fieldNameValue: any;

export function* onComplianceTestClick(action: OnClickComplianceTestPayload): any {
  const { instanceUri, fieldName } = action.payload;
  fieldNameValue = fieldName;
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
  yield put(updateComponentViewAction(VIEW_INSPECTOR, clientViewConfiguration.uri, { isCollapsed: true }));
  return executeAction({
    action: { uri: 'instance/actions/hypo_fetch_test_result_details' },
    target_uri: instanceUri,
  });
}

export function* onGridCellClick(action: OnGridCellClickPayload): any {
  const { _uri: instanceUri, fieldName } = action.payload;
  const clientViewConfiguration = yield select(selectCVC);
  fieldNameValue = fieldName || action.payload['Display Name'];
  // TODO: selecting a row should update components.viewComponents.instanceUri
  // const { instanceUri, instanceSensitiveObject } = yield select(
  //   selectViewComponent,
  //   'bottomPanel',
  //   clientViewConfiguration.uri,
  // );
  const bottomPanelCvc = yield select(selectClientViewBottomPanel);
  const instanceSensitiveObject = bottomPanelCvc.filter(
    (cvc: ClientViewConfiguration) =>
      cvc.data?.hasOwnProperty('instance_sensitive_field_name') &&
      cvc.data?.instance_sensitive_field_name?.includes('fields/compliance_test_current_result'),
  );
  // @ts-ignore
  const instanceSensitiveBottomPanel = yield select(selectInstanceSensitiveBottomPanel, fieldNameValue);
  const isBottomPanel = instanceSensitiveBottomPanel.length > 0;

  if (clientViewConfiguration.is_time_series || isBottomPanel) {
    yield updateComponentViewAction('bottomPanel', clientViewConfiguration.uri, {
      instanceUri,
      // instanceSensitiveObject: instanceSensitiveFieldName,
      isVisible: true,
    });

    if (!isBottomPanel) {
      yield put(
        updateComponentViewAction(VIEW_INSPECTOR, clientViewConfiguration.uri, {
          isCollapsed: true,
        }),
      );
    }
  }
  yield put(
    updateComponentViewAction('bottomPanel', clientViewConfiguration.uri, {
      instanceUri: action?.payload?._uri,
      instanceSensitiveObject,
    }),
  );
  // No instance uri means no instance sensitive views need to populate content
  if (!instanceUri || !instanceSensitiveObject || !instanceSensitiveObject.length) return;

  // Find the matching instance sensitive panel
  if (instanceSensitiveObject && instanceSensitiveObject.length === 1) {
    const filterUri = instanceSensitiveObject[0]?.data?.filter_uri?.split('?');
    const searchParams = new URLSearchParams(filterUri[1]);
    const fieldNames = searchParams.get('fieldnames');
    yield call(fetchClientView, {
      fieldNames,
      instanceUri,
      bottomPanelUri: instanceSensitiveObject[0]?.uri,
    });
    // update view component state so that glide-views reacts to that state by rendering the bottom panel

    if (clientViewConfiguration.uri === 'instance/client_view_configuration/hypothetical_scenario_blotter') {
      const queryParams: GlideRequestBody = {
        uri: 'instance/client_view_configuration/compliance_test_results',
        hypothetical_scenario: instanceUri,
      };

      yield putResolve(
        requestAsync(
          glideQuery({
            endpoint: '/glide/view',
            body: queryParams,
          }),
        ),
      );

      yield put(
        updateComponentViewAction('bottomPanel', clientViewConfiguration.uri, {
          instanceUri,
          // instanceSensitiveObject: instanceSensitiveFieldName,
          isVisible: true,
        }),
      );
    }
    yield put(
      updateComponentViewAction('bottomPanel', clientViewConfiguration.uri, {
        isVisible: true,
        clientViewUri: instanceSensitiveObject[0]?.uri,
      }),
    );
    yield put(
      updateComponentViewAction('bottomPanelExpandedState', clientViewConfiguration.uri, {
        isExpanded: true,
      }),
    );
  } else if (instanceSensitiveObject.length > 1) {
    console.warn(`[config error] multiple bottom panels found for ${instanceUri}. Cannot render!`);
  }
}
export function* onClickInspector(action: OnClickInspectorPayload): any {
  const { instanceUri } = action.payload;
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
  yield call(onInspectorRelease);
  yield put(resetInspectorForm(clientViewConfiguration.uri));
  yield put(updateComponentViewAction('currentObjectInView', clientViewConfiguration.uri, { uri: instanceUri }));
  yield put(updateComponentViewAction(VIEW_INSPECTOR, clientViewConfiguration.uri, { isCollapsed: false }));
  yield put(
    updateComponentViewAction('bottomPanel', clientViewConfiguration.uri, {
      instanceUri: instanceUri,
      isVisible: false,
    }),
  );

  yield put(
    updateComponentViewAction('bottomPanelExpandedState', clientViewConfiguration.uri, {
      isExpanded: true,
    }),
  );

  yield put(
    updateComponentViewAction('inspector', clientViewConfiguration.uri, {
      isCollapsed: false,
    }),
  );

  dispatchActions.db.fetchView({
    endpoint: '/glide/display-view/groups',
    body: {
      uri: instanceUri,
      fetch_options: 'workflow_transitions',
      expand_prop: 'actions',
    },
    storeViewName: clientViewConfiguration.uri,
    storeViewProp: VIEW_INSPECTOR,
  });
}

export function* onClickGlobalInspector(action: OnClickGlobalInspectorPayload): any {
  const { instanceUri } = action.payload;
  yield put(resetInspectorForm('global'));
  yield put(
    updateComponentAction('global', {
      currentObjectInView: { uri: instanceUri },
      bottomPanel: {
        instanceUri: '',
        isVisible: false,
      },
      bottomPanelExpandedState: {
        isExpanded: false,
      },
      inspector: {
        isCollapsed: false,
      },
      [VIEW_INSPECTOR]: {
        isCollapsed: false,
      },
    }),
  );

  dispatchActions.db.fetchView({
    endpoint: '/glide/display-view/groups',
    body: {
      uri: instanceUri,
      fetch_options: 'workflow_transitions',
      expand_prop: 'actions',
    },
    storeViewName: 'global',
    storeViewProp: VIEW_INSPECTOR,
  });
}

// "any" interface is used as some bottom panel props are used there
// TODO: this would be addressed  in feat/glide-view-bottom-panel
export function* fetchClientView(action: ActionPayLoad | any): any {
  const cvcUri = yield select(activeTabSelector);
  const { clientViewUri } = action.payload || cvcUri;
  const clientViewData = yield select(selectClientViewData);
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC, clientViewUri);
  if (clientViewData && clientViewConfiguration.is_caching_enabled && !action.payload.isManualRefresh) {
    console.info('clientViewUri', clientViewUri, ' has data ');
    return;
  }
  yield put(
    updateComponentViewAction('gridLayout', clientViewConfiguration.uri, {
      filteredLayouts: null,
    }),
  );
  const selectedFundsUri: any[] = yield select(selectedFundURIs, clientViewConfiguration);
  const hasFundSelector =
    clientViewConfiguration.client_view_type === ClientViewTypeLookUps.Custom ||
    clientViewConfiguration.toolbar_buttons?.includes('instance/toolbar_buttons/portfolio_manager');
  // clientViewConfiguration.uri.includes('portfolio') || clientViewConfiguration.uri.includes('all_cash');
  const hasBottomPanel = action?.bottomPanelUri && action?.instanceUri && action?.fieldNames;
  let queryParams: GlideRequestBody = { uri: clientViewConfiguration.uri };

  if (clientViewConfiguration.is_time_series) {
    const datePicker = yield select(selectViewComponent, 'datePicker', clientViewConfiguration.uri);
    const datePicked = datePicker?.data?.singleDate;
    if (datePicked) {
      // pass dateConfig to API
      const datePickedDateObj = new Date(datePicked);
      const userTimezoneOffset = datePickedDateObj.getTimezoneOffset() * 60000;
      const validDateToQuery = new Date(datePickedDateObj.getTime() - userTimezoneOffset);
      queryParams['date'] = validDateToQuery;
    }
  }

  if (hasFundSelector) {
    const portfolios = yield select(Glide.selector, 'portfolios');
    // populate funds if needed
    if (!portfolios) {
      yield putResolve(
        requestAsync(
          glideQuery({
            endpoint: '/gsearch',
            transform: (response: any) => {
              return { portfolios: response };
            },
            options: { method: 'POST' },
            body: { object_type: 'funds', return_all_fields: true },
            update: {
              portfolios: (_: any, next: any) => next,
            },
          }),
        ),
      );
    }
  }

  if (hasBottomPanel) {
    // pass bottom panel CVC with filter value as selected row instance uri to API
    queryParams['uri'] = action?.bottomPanelUri;
    queryParams[action?.fieldNames] = action?.instanceUri;
  }

  if (
    clientViewConfiguration.uri === 'instance/client_view_configuration/proposed_order_blotter' &&
    selectedFundsUri[0] === 'EMPTY_GRID'
  )
    return;

  if (clientViewConfiguration.uri == 'instance/client_view_configuration/compliance_dashboard') {
    if (selectedFundsUri) {
      const baseLineDate = clientViewConfiguration.has_baseline_date
        ? yield select(selectViewComponent, 'baseLineDate', clientViewConfiguration.uri)
        : '';
      const currentDate = yield select(selectViewComponent, 'currentDate', clientViewConfiguration.uri);

      queryParams = {
        uri: clientViewConfiguration?.uri,
        fund: selectedFundsUri.join(','),
        start_date: baseLineDate?.data?.singleDate,
        end_date: currentDate?.data?.singleDate,
      };

      yield putResolve(
        requestAsync(
          glideQuery({
            endpoint: '/glide/view',
            body: queryParams,
          }),
        ),
      );
    } else {
      const actionLinks = [
        {
          title: 'Open Fund Selector',
          onClick: () => {
            dispatchActions.components.updateView('fundSelector', clientViewConfiguration.uri, { visible: true });
          },
        },
      ];
      yield put({
        type: NotificationsAction.NOTIFICATION,
        payload: { title: '', message: 'Please select funds using Portfolio selector.', actionLinks: [...actionLinks] },
      });
    }
    return;
  }

  if (selectedFundsUri) {
    const datePicker = yield select(selectViewComponent, 'datePicker', clientViewConfiguration.uri);
    const datePicked = datePicker?.data?.singleDate;
    queryParams = {
      uri: clientViewConfiguration.uri,
      fund: selectedFundsUri.join(','),
      order_date: datePicked ? new Date(datePicked) : formattedDate(new Date()).formattedDate,
    };
  }

  // commenting as the 'summary_all' is default field foramt from API side for the Client Views.
  // if (
  //   clientViewConfiguration.uri === 'instance/client_view_configuration/credit_dashboard' ||
  //   clientViewConfiguration.uri === 'instance/client_view_configuration/deal_pipeline'
  // ) {
  //   queryParams = {
  //     uri: clientViewConfiguration.uri,
  //     field_display_format: 'summary_all',
  //   };
  // }
  yield call(onInspectorRelease);
  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/glide/view',
        body: queryParams,
      }),
    ),
  );
}
export function* updateScenarioResultColumn(action: any): any {
  const clientViewData = yield select(selectClientViewData);
  const values: any = Object.values(action.payload.data);
  const payload = JSON.parse(values);
  clientViewData['data'] = clientViewData.data.map((eachData: any) => {
    if (eachData._uri === payload._uri) {
      eachData['Current Result'] = payload.hasOwnProperty('Current Result') && payload['Current Result'];
    }
    return eachData;
  });

  updateEntities({
    views: (prev: any, _: any) => ({
      ...prev,
      [clientViewData.uri]: {
        ...prev?.[clientViewData.uri],
        ...clientViewData,
      },
    }),
  });
  yield put(
    updateComponentViewAction('hypoScenario', clientViewData.uri, {
      runStatus: action.payload.resolved_entity_type,
      runStatusUri: action.payload.resolved_entity_uri,
    }),
  );
}

export function* openCreditDetailInspector(action: any): any {
  const { instanceUri } = action.payload;
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
  yield put(
    updateComponentViewAction('currentObjectInView', clientViewConfiguration.uri, {
      uri: instanceUri,
    }),
  );
  yield put(resetCreditDetailForm());
  yield put(
    updateComponentAction(CREDIT_DETAIL_INSPECTOR, {
      isVisible: true,
    }),
  );
  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/glide/display-view/groups',
        body: {
          uri: action.payload?.instanceUri,
          fetch_options: 'workflow_transitions',
          expand_prop: 'actions',
          active_dates: action.payload?.active_dates,
          active_view: action.payload?.active_view,
        },
        transform: (body: any) => ({
          [CREDIT_DETAIL_INSPECTOR]: parseDisplayViewData(body),
        }),
        update: {
          [CREDIT_DETAIL_INSPECTOR]: (prev: any, next: any) => {
            if (action.payload?.active_view) {
              const updatedData = {
                ...prev,
                tab_data: {
                  ...prev.tab_data,
                  [`instance/display_views/${action.payload?.active_view}`]: next.tab_data[
                    `instance/display_views/${action.payload?.active_view}`
                  ],
                },
              };
              return updatedData;
            }
            return next;
          },
        },
      }),
    ),
  );
  const creditDetailInspectorData = yield select(selectCreditDetailInspectorData);
  yield put(setCreditDetailForm(creditDetailInspectorData));
}

export const cellClickAction = (payloadArguments: OnGridCellClickPayloadArguments) => ({
  type: GlideViewActions.ON_GRID_CELL_CLICK,
  payload: payloadArguments,
});

export const cellClickInspector = (payloadArguments: OnClickInspectorPayloadArguments) => ({
  type: GlideViewActions.INIT_INSPECTOR,
  payload: payloadArguments,
});

export const globalCellClickInspector = (payloadArguments: OnClickInspectorPayloadArguments) => ({
  type: GlideViewActions.INIT_GLOBAL_INSPECTOR,
  payload: payloadArguments,
});

export const complianceClickInspector = (payloadArguments: OnClickCompliancePayloadArguments) => ({
  type: GlideViewActions.OPEN_TEST_RESULTS,
  payload: payloadArguments,
});

export const creditDetailInpector = (payloadArguments: any) => ({
  type: GlideViewActions.OPEN_CREDIT_DETAIL,
  payload: payloadArguments,
});

export function* watchFetchClientView() {
  yield takeLatest<any>(GlideViewActions.FETCH_CLIENT_VIEW, fetchClientView);
}

export function* watchGridCellClick() {
  yield takeLatest<any>(GlideViewActions.ON_GRID_CELL_CLICK, onGridCellClick);
}
export function* watchComplianceCellClick() {
  yield takeLatest<any>(GlideViewActions.OPEN_TEST_RESULTS, onComplianceTestClick);
}
export function* watchCellClickInspector() {
  yield takeLatest<any>(GlideViewActions.INIT_INSPECTOR, onClickInspector);
}
export function* watchGlobalCellClickInspector() {
  yield takeLatest<any>(GlideViewActions.INIT_GLOBAL_INSPECTOR, onClickGlobalInspector);
}
export function* watchRunHypoScenario() {
  yield takeLatest<any>(GlideViewActions.UPDATE_SCENARIO_RESULT_COLUMN, updateScenarioResultColumn);
}
export function* watchCreditDetailInspector() {
  yield takeLatest<any>(GlideViewActions.OPEN_CREDIT_DETAIL, openCreditDetailInspector);
}

export default [
  watchFetchClientView,
  watchRunHypoScenario,
  watchGridCellClick,
  watchCellClickInspector,
  watchGlobalCellClickInspector,
  watchComplianceCellClick,
  watchCreditDetailInspector,
];
