export type OptionElement = {
  name: string;
  value: string;
  color?: string;
};

export type FormFieldOptions = {
  [key: string]: OptionElement;
};

export type FormInputs =
  | 'DateInput'
  | 'DecimalInput'
  | 'SearchInput'
  | 'SelectInput'
  | 'TogglerInput'
  | 'MultipleDateInput'
  | 'TextInput'
  | 'MultipleSelectInput'
  | 'CheckboxInput';

export enum FieldRuleType {
  Filter = 'Filter',
  ReadOnly = 'Read Only',
  Required = 'Required',
  Valid = 'Valid',
  Value = 'Value',
  Hidden = 'Hidden',
}
export type FieldRule = {
  ruleFn: (...args: any[]) => boolean | string | number | undefined | null;
  ruleType: FieldRuleType;
  [key: string]: any;
};

export interface FieldRuleProps {
  fieldName: string;
  fieldRules: FieldRule[] | undefined;
  formValues: { [key: string]: any };
  fieldUpdateFn?: (targetValues: any, groupName?: string) => void;
  isEdit?: boolean;
  objectFormField?: any;
  onEdit?: any;
}

export interface FormFieldType {
  fdddisplay?: string;
  dataType: string;
  formElementType: string;
  required: boolean;
  readonly: boolean;
  defaultValue: any;
  name: string;
  displayName: string;
  id?: string;
  options?: FormFieldOptions;
  disabled?: boolean;
  formatMultiplier?: Array<{ key: string; value: string }>;
  searchType?: string;
  searchService?: any;
  hidden?: boolean;
  disableErrorWarning?: boolean;
  data?: any[];
  columns?: any[];
  objectFieldName?: string;
  client_region?: string;
  fieldRules?: FieldRule[];
  calendarAlign?: string;
  renderType?: string | null;
  format?: string;
  action?: string;
  hideLabel?: boolean;
  textDark?: boolean;
  isEdited?: boolean;
  isMultiSelect?: boolean;
  isSingleDate?: boolean;
  fieldUri?: string;
  value?: any;
  isInvalid?: boolean;
  isEditing?: boolean;
  is_search_enable?: boolean;
  isLinkEnabled?: boolean;
  uri?: any;
}

export interface GField {
  aggregation_calculation: string;
  aggregation_type: string;
  category: string;
  data_type: string;
  description: string;
  display_multiplier: string;
  display_name: string;
  format: string;
  field_name: string;
}

export type FormValuesType = {
  formValues: any;
  formErrors: any;
  formFields: FormFieldsType;
  hasChanged?: boolean;
};

export type FormFieldsType = { [key: string]: FormFieldType };

export type FormGroupsStateType = {
  formErrors: { [key: string]: string };
  hasChanged: boolean;
  formFields: FormFieldsType;
  formValues: { [key: string]: string | number | boolean | undefined };
  formInitialChanges: { [key: string]: string | number | boolean | undefined };
};

export type FormGroupsType = {
  [key: string]: FormFieldsType;
};

export type Form = {
  [key: string]: FormGroupsStateType;
};

export interface RadioButtonsType {
  value: string;
  display_name: string;
  disabled?: boolean;
  visible?: boolean;
}
