import React, { useEffect, useState } from 'react';
import * as S from './modal-inspector.style';
import { PanelHeader } from '@virtus/components/Panel/PanelHeader';
import { RootState } from 'src/reducers';
import { selectGlideObjectLoading } from 'src/api/query';
import { connect } from 'react-redux';
import { dispatchActions } from 'src/app/store';
import GlideInspectorFooter from 'src/components/inspectors/glide-inspector/glide-inspector-footer';
import { DisplayViewData, DisplayViewGroup } from 'src/api/queries/display-view';
import { selectExpandedAction } from 'src/sagas/action/actions.saga';
import { GlideObject } from 'src/models/glide/glideObject';
import { ModalInspectorProps, selectStaticComponent } from 'src/reducers/components';
import {
  closeModalForm,
  discardModalFormChanges,
  modalForm,
  modalFormState,
  selectModalForm,
} from 'src/reducers/modalForm.reducer';
import { CONFIRMATION_DIALOG, backDropClick } from 'src/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { activeTabSelector } from 'src/reducers/tabs';
import GlideDataContent from 'src/components/forms/glide-data-content/glide-data-content';
interface ModalInspector {
  displayName: string;
  uri: string;
  displayViewData?: DisplayViewData;
}

interface ModalComponentProps {
  objectInView: string;
}
interface ReduxProps {
  isGlideObjectLoading: any;
  modalForm: modalFormState;
  modalInspectorData: { [key: string]: DisplayViewGroup };
  modalInspector: ModalInspectorProps;
  expandedAction?: GlideObject;
  clientViewUri?: any;
  modalInspectorObjUri: string;
}

export interface ModalInspectorReduxDispatchProps {
  discardModalFormChanges: (displayConfirmationDialog: Function, hideInspector: boolean, objectInView: string) => void;
  closeModalForm: (hideInspector: boolean, objectInView: string) => void;
}

export const MODAL_INSPECTOR = 'modalInspector';

export const ModalInspector = ({
  modalForm,
  clientViewUri,
  modalInspectorData,
  modalInspector,
  expandedAction,
  isGlideObjectLoading,
  objectInView,
  modalInspectorObjUri,
  discardModalFormChanges,
  closeModalForm,
}: ReduxProps & ModalInspectorReduxDispatchProps & ModalComponentProps) => {
  if (objectInView == '' && modalInspector[modalInspectorObjUri]?.isVisible) {
    objectInView = modalInspectorObjUri;
  }
  if (
    (modalInspectorData && !Object.keys(modalInspectorData).includes(objectInView)) ||
    (modalInspector && !modalInspector[objectInView]?.isVisible)
  ) {
    return null;
  }
  const [data, setData] = useState<any>(null);

  const closeModalInspectorDialog = () => {
    closeModalForm(true, objectInView);
  };

  const { DialogComponent: QuitModalInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeModalInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  const onClose = (_event: any, reason?: any) => {
    if (reason === backDropClick) return;
    discardModalFormChanges(displayConfirmationDialog, true, objectInView);
  };

  const onChangeField = (fieldChange: any, formGroupName: string, fieldRules?: any) => {
    dispatchActions.modalForm.changeModalFormField(fieldChange, formGroupName, fieldRules, objectInView);
  };

  useEffect(() => {
    if (modalInspectorData) {
      // expandedAction is for global actions and for inspectorData, the actions are available in the displayViewData
      if (!modalInspectorData[objectInView].displayViewData?.actions && expandedAction) {
        modalInspectorData[objectInView].displayViewData = expandedAction?.data;
      } else {
        //update modal inspector form data
        if (modalForm.uri && !(Object.keys(modalForm.uri[objectInView]).length > 0)) {
          dispatchActions.modalForm.setModalForm(modalInspectorData[objectInView], clientViewUri);
        }
      }
      setData(modalInspectorData[objectInView]);
    }
  }, [modalInspectorData, expandedAction]);

  if (!data) return null;
  const groupslength = Object.keys(data.data).length;
  // @ts-ignore
  console.info('modalInspectorData[objectInView].displayName', modalInspectorData[objectInView].displayName);
  return (
    <>
      <QuitModalInspector />
      <S.Dialog open onClose={onClose} groupslength={groupslength} disableEnforceFocus={true} disableEscapeKeyDown>
        {isGlideObjectLoading ? (
          <Loading show={true} type={LoadingIconType.Glide} size={LoadingIconSizes.extraLarge} full />
        ) : (
          <>
            <PanelHeader
              showCloseBtn
              showBackButton={false}
              title={modalInspectorData[objectInView].displayName}
              isExpanded={true}
              tooltipText={modalInspectorData[objectInView].displayName}
              onClose={onClose}
            />
            <S.ModalWrapper>
              <S.Grid data-testid="modal-inspector" groups={groupslength}>
                {Object.entries(data?.data).map(([formGroupName, value]) => {
                  const groupName = formGroupName.length > 1 ? formGroupName : '';
                  return (
                    <S.Cell takeAllHeightAvailable background key={formGroupName}>
                      <S.CellHeader>{groupName}</S.CellHeader>
                      <GlideDataContent
                        uri={modalInspectorData[objectInView].uri}
                        dataTestId={modalInspectorData[objectInView].uri}
                        optionalStyles={{ noPadding: true, noBackground: true }}
                        hideFooter
                        form={modalForm?.uri[objectInView] as modalForm}
                        fieldRulesObject={{
                          formValues: modalForm?.uri[objectInView].formValues,
                          formFields: modalForm?.uri[objectInView].formFields,
                        }}
                        formName={formGroupName}
                        onChangeField={onChangeField}
                        formData={value}
                        isEditing={modalForm?.uri[objectInView].isEdit}
                      />
                    </S.Cell>
                  );
                })}
              </S.Grid>
            </S.ModalWrapper>
            {modalInspectorData[objectInView].displayViewData?.actions && (
              <GlideInspectorFooter
                actions={modalInspectorData[objectInView].displayViewData?.actions}
                workflowActions={modalInspectorData[objectInView].displayViewData?.workflow_transitions}
                targetInstance={modalInspectorData[objectInView].displayViewData?.instance}
                isExpanded
                isModalInspector={modalInspector[objectInView]?.isVisible}
                instanceUri={objectInView}
              />
            )}
          </>
        )}
      </S.Dialog>
    </>
  );
};
export const selectModalInspectorData = (state: RootState): { [key: string]: DisplayViewGroup } =>
  state.entities[MODAL_INSPECTOR];

export const selectModalInspectorObjUri = (state: RootState): any =>
  state.components[MODAL_INSPECTOR]['modalInspectorObjUri'];

const mapStateToProps = (state: RootState): ReduxProps => ({
  expandedAction: selectExpandedAction(state),
  clientViewUri: activeTabSelector(state),
  modalForm: selectModalForm(state),
  isGlideObjectLoading: selectGlideObjectLoading(state),
  modalInspectorData: selectModalInspectorData(state),
  modalInspector: selectStaticComponent(MODAL_INSPECTOR)(state),
  modalInspectorObjUri: selectModalInspectorObjUri(state),
});

const mapDispatchToProps = (dispatch: any): ModalInspectorReduxDispatchProps => ({
  discardModalFormChanges: (displayConfirmationDialog: Function, hideInspector: boolean, objectInView: string) =>
    dispatch(discardModalFormChanges(displayConfirmationDialog, hideInspector, objectInView)),
  closeModalForm: (hideInspector: boolean, objectInView) => dispatch(closeModalForm(hideInspector, objectInView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalInspector);
