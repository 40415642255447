import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import { useDebounce } from '@virtus/components/hooks/useDebounce';
import { LoadingIconGlide } from '@virtus/components/LoadingIcon';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import { Select } from 'src/components/forms/form-elements/form-fields/SelectInput';
import * as S from './SearchInput.style';
import { useSearchWarning } from '@virtus/components/hooks/useSearchWarning';
import { useDispatch } from 'react-redux';
import { Action } from 'src/api/mutations';
import { useSelector } from 'react-redux';
import { InspectorsAction } from 'src/reducers/inspectors';

type SearchResult = { name: string; value: string };

export const SearchInput = ({
  field,
  value = '',
  error,
  onChange,
  linkCallback,
  fundList,
  formGroupChildrenStyle,
  formGroupStyle,
}: Omit<FormElementInputComponent, 'formValues'>) => {
  const [hasFocus, setFocus] = React.useState(false);
  if (field?.hidden) return null;
  const MINIMUM_CHARACTERS_TO_SEARCH = 3;
  const EMPTY_SELECT_ELEMENT = { name: '', value: '' };
  const createdObjectUri = useSelector(Action.newObjectUri);
  const [searchTerm, setSearchTerm] = useState(field.defaultValue as string);
  const [searchResults, setSearchResults] = useState([]);
  const [selectMinWidth, setSelectMinWidth] = useState('100px');
  const [showSpinner, setShowSpinner] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const searchError = !field.readonly
    ? useSearchWarning(debouncedSearchTerm, MINIMUM_CHARACTERS_TO_SEARCH, error)
    : undefined;

  useEffect(() => {
    const valueInResults = searchResults.find((result: SearchResult) => result.name === debouncedSearchTerm);
    if (
      debouncedSearchTerm !== value &&
      !valueInResults &&
      debouncedSearchTerm.length >= MINIMUM_CHARACTERS_TO_SEARCH &&
      !field.disabled
    ) {
      setShowSpinner(true);
      searchInAPI(searchTerm);
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setSearchTerm(field.defaultValue as string);
  }, [field]);

  const searchInAPI = debounce((text: string) => {
    const searchWidth = Number(
      getComputedStyle(document.getElementById('search-input-field')!).getPropertyValue('width').replace('px', ''),
    );
    setSelectMinWidth(`${searchWidth > 250 ? searchWidth / 2 : 20}px`);
    field
      .searchService(text, field.searchType, true)
      .execute((_err: any, _resStatus: number, resBody: any, _resText: string, _resHeaders: object) => {
        let results = JSON.parse(resBody.search_results).map((result: { name: string; uri: string }) => ({
          name: result.name,
          value: result.uri,
        }));
        if (fundList?.length) {
          // checking with both name and URI
          results = results.filter((item: any) => fundList.indexOf(item.name) < 0 && fundList.indexOf(item.value) < 0);
        }
        setSearchResults(results);
        setShowSpinner(false);
        setOpen(true);
      });
  }, 1000);

  const nonDisabledSpinner = !(field.disabled || field.readonly) ? <S.StyledSearchIcon /> : null;

  const addActionButton = field.action ? (
    <S.StyledAddCircleIcon
      onClick={() => {
        dispatch({ type: InspectorsAction.OPEN_INSPECTOR, uri: field.action, configUri: 'overlay_views' });
      }}
    />
  ) : null;

  const searchValue = () => {
    if (searchTerm.length > 0) {
      return searchTerm;
    }
    //commenting out this code as it is creating a regression in edit search field
    // //set the value if field has default value
    // else if (field.searchType && field.defaultValue) {
    //   return field.defaultValue;
    // }
    else {
      return field.action && field.searchType && createdObjectUri ? createdObjectUri[field.searchType] : '';
    }
  };

  return (
    <FormErrorWrapper
      error={searchError}
      displayName={field.displayName}
      hideLabel={field.hideLabel}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      readonly={field.readonly}
      disableErrorWarning={field.disableErrorWarning}
      formGroupChildrenStyle={formGroupChildrenStyle}
      formGroupStyle={formGroupStyle}
    >
      <S.SearchContainer
        disabled={field.disabled || field.readonly}
        editIsEnabled={!field.disabled && !field.readonly}
        hasFocus={hasFocus}
        error={Boolean(error) && !(field.readonly || field.disabled)}
      >
        {linkCallback ? (
          <S.SearchFieldWithLink
            id="search-input-field"
            data-testid={field.name}
            autoComplete="off"
            disabled={field.disabled || field.readonly}
            placeholder={!field.disabled && !field.readonly ? 'Search' : ''}
            value={searchTerm}
            onClick={() => (field.readonly || field.disabled ? linkCallback() : null)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
              if (onChange && !e.target.value.length) onChange({ target: { value: '', name: '' } } as any);
            }}
          />
        ) : (
          <S.Search
            id="search-input-field"
            data-testid={field.name}
            autoComplete="off"
            disabled={field.disabled || field.readonly}
            placeholder={!field.disabled && !field.readonly ? 'Search' : ''}
            value={searchValue()}
            darktext={field.textDark}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
              if (onChange && !e.target.value.length) onChange({ target: { value: '', name: '' } } as any);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
        )}
        {searchResults.length ? (
          <Select
            open={open}
            style={{
              background: 'none',
              minWidth: selectMinWidth,
            }}
            menuItems={searchResults}
            value=""
            disabled={field.readonly || field.disabled}
            onClose={() => {
              setOpen(false);
            }}
            onOpen={() => {
              setOpen(true);
            }}
            onChange={(e: any) => {
              const element: SearchResult =
                searchResults.find((result: SearchResult) => result.value === e.target.value) || EMPTY_SELECT_ELEMENT;
              setSearchTerm(element.name);
              e.target.name = element.name;
              if (onChange) onChange(e);
            }}
          />
        ) : null}
        {showSpinner ? (
          <S.SpinnerWrapper>
            <LoadingIconGlide size="small" show={true} />
          </S.SpinnerWrapper>
        ) : (
          nonDisabledSpinner
        )}
        {addActionButton}
      </S.SearchContainer>
    </FormErrorWrapper>
  );
};
