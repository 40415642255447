import { GlideDataSource, glideDataTypeMap, GlideSchema } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { TotalItem } from 'devextreme-react/data-grid';
import { LoadingIconGlide } from '@virtus/components/LoadingIcon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import summaries from 'src/components/glide-object-manager/utils/dxdatagrid-summaries';
import validators from 'src/components/glide-object-manager/utils/dxdatagrid-validators';
import { GridView } from './grid-view/grid-view';
import { Header } from './header/header';
import { CustomCellRenderKeyProps } from '@virtus/components/DxDataGrid/DxDataGrid';
import { CellData } from 'src/components/grids/dxgrid-client-view/dxgrid-client-view.model';
import { getObjectCollectionColumns } from 'src/components/glide-object-manager/utils/get-object-collection-cells';
import {
  Action,
  Changes,
  ColumnSummary,
  IAddAPIRulesToDataSource,
  IAddValidatorsToDataSource,
  Inspector,
} from 'src/components/glide-object-manager/model';
import * as S from './style';
import { getFormPropsForGlideObjectManager } from 'src/components/forms/glide-data-content/glide-data-content';
import { SearchInput } from 'src/components/forms/form-elements/form-fields';
import SearchService from 'src/services/search.service';
import { FormFieldType } from 'src/components/forms/form-elements';
import { footerSummaryFields } from 'src/utils/common';
import {
  buttonIconProps,
  ButtonsToExclude,
  GridEditProps,
  LayoutRegion,
  otherActionsConfig,
} from './glide-object-manager.model';
import { ToolBarButtonsType } from 'src/components/grids/dxgrid-client-view';
import { GridViewProps } from './grid-view/model';
import { useGridFieldRule } from 'src/hooks/use-grid-field-rule';
import { useObjectActions } from 'src/hooks/useObjectActions';
import { connect, useSelector } from 'react-redux';
import { activeTabSelector } from 'src/reducers/tabs';
import { RootState } from 'src/reducers/rootReducer';
import { glideQuerySelector } from 'src/api/query';
import { RowAction } from 'src/components/grids/grid-action-column/grid-action-column';
import { ComponentProps, Components, selectComponents } from 'src/reducers/components';
import { dispatchActions, store } from 'src/app/store';
import { actionTypes } from 'redux-query';
import { VIEW_INSPECTOR } from 'src/components/inspectors/glide-object-inspector/view-inspector';
import { applyMultiplier } from 'src/components/forms/form-elements/form-fields/DecimalInput/DefaultMultiplier';
import { selectModalForm } from 'src/reducers/modalForm.reducer';
import { useGlideObjectManager } from '../hooks/use-glide-object-manager';
import { CONFIRMATION_DIALOG } from '@virtus/components/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { NotificationsAction } from 'src/reducers/notifications';

interface ReduxProps {
  components: Components;
  modalForm: any;
}

export type popupLayoutGOMProps = {
  onBack: () => void;
  getDataGrid: (args: {
    object_uri: string;
    object_field_name: string;
    object_form_props: any;
    selected_row_data: any;
  }) => void;
};

export type GlideObjectManagerComponentProps = {
  layout?: LayoutRegion;
  disableEditMode?: boolean;
  objectCollectionUri?: string;
  formProps?: any;
  loading?: boolean;
  enableEdit?: boolean;
  dataSource: GlideDataSource;
  fieldName: string;
  actions?: Action[];
  inspectors?: Inspector[];
  onRefresh: () => void;
  onGridViewChangesSaved?: ({ changedRows, newRows, currentAmountOfItems }: Changes) => void;
  displayType?: string;
  handleExpansion?: () => void;
  showHeader?: boolean;
  overrideGridViewProps?: {
    allowDeleting?: boolean;
    usePagination?: boolean;
    skipkeyId?: boolean;
    ignoreDxSaveButton?: boolean;
    skipSchema?: boolean;
  };
  overridePopupGOMProps?: popupLayoutGOMProps;
  onCustomToolBarPreparing?: () => (e: any) => void;
  parseGridData?: (data: any) => any;
  toggleEditOnCancel?: boolean;
  customizeGridColumns?: (e: any) => void;
  customDxToolbarButtonsActions?: any;
  selectedRowData?: { [key: string]: any };
  displayViewData?: any;
  editObjectIcons?: any;
  removeIconsConfig?: any;
  showFilterButton?: boolean;
  showRefreshButton?: boolean;
  isSearchPanelLeftAligned?: boolean;
};

const initialGridEditProps: GridEditProps = {
  edit: false,
  editMode: 'batch',
  allowUpdating: false,
  allowDeleting: false,
  allowAdding: false,
  allowSelection: false,
  allowMultipleSelection: false,
};

export const getSummariesFromDataSource = (fieldName: string) => {
  const fieldSummaries = (summaries as any)[fieldName];
  if (!fieldSummaries) return undefined;
  return fieldSummaries.map((props: ColumnSummary) => <TotalItem key={props.column} {...props} />);
};

export const addAPIRulesToDataSource = ({
  dataSource = { schema: [], data: [], fieldRules: {} },
  externalProps,
}: IAddAPIRulesToDataSource): GlideDataSource => {
  const nextDataSource = {
    ...dataSource,
    schema: dataSource?.schema?.map(column => {
      const columnFieldRules = dataSource.fieldRules?.[`fields/${column.dataField}`];
      return {
        ...column,
        editProps: {
          parentValues: externalProps?.formProps?.parent,
          apiRules: columnFieldRules || [],
        },
      };
    }),
  };
  return nextDataSource;
};

export const addValidatorsToDataSource = ({
  fieldName,
  dataSource = { schema: [], data: [], fieldRules: {} },
  externalProps,
}: IAddValidatorsToDataSource) => {
  const nextDataSource = {
    ...dataSource,
    schema: dataSource?.schema?.map(column => {
      const fieldValidators = validators[fieldName];
      if (!fieldValidators) return column;
      const columnValidator = fieldValidators[column.display_name || column.caption || ''];
      return {
        ...column,
        validationRules: columnValidator
          ? columnValidator({
              externalProps,
            })
          : undefined,
      };
    }),
  };
  return nextDataSource;
};

// const isGridEdited = (ref: any) => {
//   const element = ref?.current?.instance.element().querySelectorAll('[title="Save changes"][tabindex="0"]')[0];
//   if (element) {
//     return !element.classList.contains('dx-state-disabled');
//   }
//   return false;
// };

export const getUpdatedRowsData = (currentData: any, editedData: any) => {
  const newRows: any = [];
  const deleteRows: any = [];
  const existingUpdatedRows = currentData.reduce(
    (
      accParent: any,
      currParent: {
        data: any;
        oldData: any;
        isNewRow: boolean;
        removed: boolean;
      },
    ) => {
      if (currParent?.removed) {
        deleteRows.push(currParent.data?._uri);
        return accParent;
      }
      if (currParent?.isNewRow) {
        delete currParent.data['__KEY__'];
        newRows.push({ ...currParent.data });
        return accParent;
      }
      const updatedData = editedData && editedData.filter((ed: any) => ed.key === currParent.data._uri)[0];
      return {
        ...accParent,
        [currParent.data._uri]: updatedData && Object.keys(updatedData).length ? updatedData.data : {},
      };
    },
    {},
  );
  return [newRows, existingUpdatedRows, deleteRows];
};

const STATIC_ROW_ACTIONS = [
  { text: 'Edit', uri: 'instance/actions/edit_object', bgColor: 'var(--blue)' },
  { text: 'Info', uri: '', bgColor: 'var(--grey)' },
  { text: 'Delete', uri: 'instance/actions/edit_object', bgColor: 'var(--red)' },
];

export const GlideObjectManagerComponent = React.memo(
  React.forwardRef(
    (
      {
        components,
        customDxToolbarButtonsActions,
        customizeGridColumns,
        dataSource,
        displayType,
        displayViewData,
        editObjectIcons,
        fieldName,
        formProps,
        handleExpansion,
        layout = LayoutRegion.POPUP,
        loading,
        onCustomToolBarPreparing,
        onGridViewChangesSaved,
        overrideGridViewProps,
        overridePopupGOMProps,
        parseGridData,
        removeIconsConfig,
        selectedRowData,
        showHeader = true,
        objectCollectionUri,
        showFilterButton = true,
        showRefreshButton = true,
        onRefresh,
        isSearchPanelLeftAligned = false,
        modalForm,
      }: GlideObjectManagerComponentProps & ReduxProps,
      ref: any,
    ) => {
      const [gridEditProperty, setGridEditProperty] = useState<GridEditProps>(initialGridEditProps);
      const [orderCollectionUris, setOrderCollectionUris] = useState<string[]>([]);
      const clientViewUri = useSelector((state: RootState) => activeTabSelector(state));
      const inspectorData = useSelector((state: RootState) => glideQuerySelector(state, clientViewUri, VIEW_INSPECTOR));
      const [rowActions, setRowActions] = useState<RowAction[]>([]);
      const [isCancelEdit, setIsCancelEdit] = useState(false);
      const glideObjectManagerActions = (actionType: string, _uri: string) => {
        //We can add rowActions specific saga and try to resolve this kind of condition there.
        if (actionType.toLowerCase().includes('delete')) {
          dispatchActions.db.update({
            endpoint: '/glide/object/delete',
            body: { object_uri: objectCollectionUri, delete_content: { scenario_orders: [_uri] } },
            options: {
              method: 'POST',
              // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
              headers: { 'X-Http-Method-Override': 'DELETE' },
            },
            queryKey: 'deleteObjectInCollection',
            meta: {
              object_field_name: fieldName,
              notification: {
                [actionTypes.MUTATE_START]: `Deleting hypo scenarios`,
                [actionTypes.MUTATE_SUCCESS]: `Hypo scenarios deleted!`,
              },
            },
          });
        }
      };

      const closeGOMDiscardDialog = (action: any) => {
        setGridEditProperty(initialGridEditProps);
        if (action?.refresh) {
          onRefresh();
        } else {
          setIsCancelEdit(true);
        }
      };

      const { DialogComponent: QuitGOMDialog, onDispatcherClick: displayGomConfirmDialog } = useConfirmationDialog({
        onClick: closeGOMDiscardDialog,
        ...CONFIRMATION_DIALOG,
      });

      const handleOnRefresh = () => {
        //@ts-ignore
        if (window['gridInstances']['GOM'].current?.instance?.getController('editing')?.getChanges(true).length > 0) {
          const action = { refresh: true };
          displayGomConfirmDialog(action);
        } else {
          setGridEditProperty(initialGridEditProps);
          onRefresh();
        }
      };

      useEffect(() => {
        if (displayViewData?.displayViewData?.enable_row_actions) {
          const rowActions = STATIC_ROW_ACTIONS.map((action: any) => ({
            text: action.text,
            onClick: (_uri: string) => glideObjectManagerActions(action.text, _uri),
            bgColor: action?.bgColor,
          }));
          setRowActions(rowActions);
        } else {
          setRowActions([]);
        }
      }, [displayViewData?.displayViewData]);

      const _formProps = getFormPropsForGlideObjectManager({
        field: fieldName,
        formValues: components.global.selectedRowData,
      });

      const { mapGridFieldRules, updateCellValues } = useGridFieldRule();
      //Icons and actions config based
      const actionHandlersObject = {
        'instance/actions/edit_object': () => {
          setGridEditProperty(prev => ({
            ...prev,
            ...editObjectIcons,
          }));
        },
        'instance/actions/canceledit': () => {
          (gridViewRef?.current as any)?.instance.cancelEditData();
          setGridEditProperty(initialGridEditProps);
          orderCollectionUris.length > 0 && setOrderCollectionUris([]);
        },
      };
      const getRowDetails = () => {
        if (orderCollectionUris.length) {
          displayViewData.uri = orderCollectionUris[0];
        }
        return displayViewData;
      };

      const onClickHandler = () => {
        //@ts-ignore
        if (window['gridInstances']['GOM'].current?.instance?.getController('editing')?.getChanges(true).length > 0) {
          displayGomConfirmDialog();
          setIsCancelEdit(false);
          return { isActionComplete: false };
        } else {
          return { isActionComplete: true };
        }
      };
      // TODO: invalid
      const { primaryAction, nestedPrimaryActions, otherActions } = useObjectActions({
        rowDetails: getRowDetails(),
        actionHandlers: actionHandlersObject,
        onClickHandler,
        isOpenedFromGom: true,
        isCancelEdit: isCancelEdit,
      });

      const onObjectCollectionCellClick = useCallback(
        (cellData: CellData) => {
          const formProps = getFormPropsForGlideObjectManager({
            field: cellData.column.caption,
            formValues: cellData.data,
          });
          overridePopupGOMProps?.getDataGrid({
            object_uri: cellData.data._uri,
            object_field_name: cellData.column.caption.toLowerCase().replace(' ', '_'),
            object_form_props: formProps,
            selected_row_data: cellData.data,
          });
        },
        [overridePopupGOMProps?.getDataGrid],
      );

      const customizeColumns: any = useCallback(customizeGridColumns ? customizeGridColumns : () => {}, []);

      const objectColumns: CustomCellRenderKeyProps = useMemo(
        () => getObjectCollectionColumns(dataSource?.schema, onObjectCollectionCellClick),
        [dataSource?.schema, onObjectCollectionCellClick],
      );

      let gridViewRef = useRef(null);
      gridViewRef = ref ? ref : gridViewRef;

      //@ts-ignore
      window['gridInstances']['GOM'] = gridViewRef;

      // const restoreGridInitialState = () => {
      //   if (gridEditProperty.edit) {
      //     (gridViewRef?.current as any)?.instance.cancelEditData();
      //     setGridEditProperty(initialGridEditProps);
      //   }
      //   orderCollectionUris.length > 0 && setOrderCollectionUris([]);
      // };

      // useEffect(() => {
      //   restoreGridInitialState();
      // }, [fieldName, dataSource]);

      useEffect(() => {
        if (layout === LayoutRegion.BOTTOM || layout === LayoutRegion.POPUP) {
          mapGridFieldRules({ schema: dataSource?.schema, data: dataSource?.data, fieldRules: dataSource?.fieldRules });
        }
      }, [dataSource, mapGridFieldRules]);

      const persistChanges = useCallback(() => {
        const currentData = gridViewRef?.current
          ? (gridViewRef?.current as any).instance.getController('data')._items
          : [];

        let validChangedRows: any;
        let newData: any[] = [];
        let newRowsObject: any = [];
        let deleteRowsObject: any = [];
        if (parseGridData) {
          const { changedRows, updatedData } = parseGridData(currentData);
          validChangedRows = changedRows;
          newData = updatedData;
        } else {
          const editingController = (gridViewRef?.current as any).instance.getController('editing');
          const changes = editingController.getChanges(true);
          const [newRows, existingUpdatedRows, deleteRows] = getUpdatedRowsData(currentData, changes);
          newRowsObject = newRows;
          validChangedRows = existingUpdatedRows;
          deleteRowsObject = deleteRows;
        }
        const is_fund_present = currentData.every((item: any) => item.data.hasOwnProperty('fund'));
        const is_fund_blank = currentData.some((item: any) => !item.data['fund']);
        if (is_fund_blank && deleteRowsObject.length <= 0 && is_fund_present) {
          store.dispatch({
            type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
            payload: {
              errorMessage: 'Fund field cannot be blank',
            },
          });
          return;
        }
        // While saving, multiply percentage columns by 100 for new and updated rows by using schema.
        dataSource?.schema?.map((column: GlideSchema) => {
          const format = column?.format;
          const dataField = column?.dataField;
          if (format && format.toLowerCase().includes('p') && dataField) {
            // For the New rows
            newRowsObject?.map((newRow: any) => {
              if (newRow.hasOwnProperty(dataField)) {
                newRow[dataField] = newRow?.[dataField] * 100;
              }
            });
            // For the updated rows and if the percentage column changed,
            Object.keys(validChangedRows).map((existingRowKey: any) => {
              if (validChangedRows[existingRowKey].hasOwnProperty(dataField)) {
                validChangedRows[existingRowKey][dataField] *= 100;
              }
            });
          }
        });

        if (onGridViewChangesSaved) {
          onGridViewChangesSaved({
            changedRows: validChangedRows,
            newRows: newRowsObject,
            currentAmountOfItems: Object.keys(validChangedRows).length + newRowsObject.length,
            updatedData: newData,
            deleteRows: deleteRowsObject,
          });
          setGridEditProperty(initialGridEditProps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dataSource?.schema, onGridViewChangesSaved]);

      //Keeping this code temporarily to decide if need to reuse or not
      /**  const handleOnRowValidating = useCallback(
       (e: { isValid: boolean }) => {
          if (e.isValid) {
            persistChanges();
          }
        },
       [persistChanges],
       ); **/
      const allowedKeys = ['Delete', 'Backspace', 'Tab', '.', 'ctrl+a', 'ctrl+v', 'ctrl+c', 'ctrl+x', 'k', 'm', 'b'];
      const allowedKeysForAllocation = ['Delete', 'Backspace', 'Tab', '.', 'ctrl+a', 'ctrl+v', 'ctrl+c', 'ctrl+x'];
      const onEditorPreparing = useCallback(
        (e: any) => {
          if (e.parentType === 'dataRow') {
            // Converting the number datatype to string for input of 1K, 1M, 1B
            if (e.dataType == 'number') {
              e.dataType = 'string';
              e.editorName = 'dxTextBox';
            }

            e.editorOptions.onKeyDown = (arg: any) => {
              let newKey: any;
              if ((arg.event.ctrlKey || arg.event.metaKey) && arg.event.key.toLowerCase() == 'v') {
                newKey = 'ctrl+v';
              } else if ((arg.event.ctrlKey || arg.event.metaKey) && arg.event.key.toLowerCase() == 'c') {
                newKey = 'ctrl+c';
              } else if ((arg.event.ctrlKey || arg.event.metaKey) && arg.event.key.toLowerCase() == 'x') {
                newKey = 'ctrl+x';
              } else if ((arg.event.ctrlKey || arg.event.metaKey) && arg.event.key.toLowerCase() == 'a') {
                newKey = 'ctrl+a';
              } else {
                newKey = arg.event.key;
              }
              if (
                !(e.caption === 'Override Comment') &&
                !allowedKeys.some(item => item.toLowerCase() == newKey.toLowerCase()) &&
                !/^\d+$/.test(newKey)
              ) {
                arg.event.preventDefault();
                arg.event.stopPropagation();
              }
              if (
                e.caption === 'Allocation %' &&
                !allowedKeysForAllocation.some(item => item.toLowerCase() == newKey.toLowerCase()) &&
                !/^\d+$/.test(newKey)
              ) {
                arg.event.preventDefault();
                arg.event.stopPropagation();
              }
            };
            e.editorOptions.onValueChanged = (data: any) => {
              const d = dataSource?.schema?.find((item: any) => item.dataField === e.dataField);
              const format = d?.format;
              const fddisplay = Number(d?.display_multiplier);
              // Identify the item which were multplied by 100 from mapSchemaGlide/calculateDisplayValue
              // and divide by 100, while saving or updating the other cell values need to handle this.
              if (format && format.toLowerCase().includes('p')) {
                const decimalVal = data.value / 100;
                e.component.cellValue(e.row.rowIndex, e.dataField, decimalVal);
              } else if (format && fddisplay) {
                const decimalVal = data.value / fddisplay;
                e.component.cellValue(e.row.rowIndex, e.dataField, decimalVal);
              } else if (format && (format.toLowerCase().includes('mm/dd/yyyy') || e.dataType === 'date')) {
                const dateValue = data.value.toISOString();
                e.component.cellValue(e.row.rowIndex, e.dataField, dateValue);
              } else {
                data.value = applyMultiplier(data.value);
                e.component.cellValue(e.row.rowIndex, e.dataField, data.value);
              }
              // Using parent grid data i.e inspector data from store for field rules calculation.
              const mergeParentData = {};
              if (inspectorData?.data && Object.keys(inspectorData?.data)?.length) {
                Object.values(inspectorData?.data).map((data: any) => {
                  const reduceData = data.reduce((acc: any, v: any) => {
                    const displayName = (Object.values(v?.field) as any)[0]?.display_name;
                    acc[displayName] = v?.value;
                    return acc;
                  });
                  Object.assign(mergeParentData, reduceData);
                });
              }

              let rowData = {};

              if (selectedRowData && Object.keys(selectedRowData).length) {
                rowData = { ...selectedRowData };
              } else if (components.global.selectedRowData && Object.keys(components.global.selectedRowData).length) {
                rowData = { ...components.global.selectedRowData };
              } else if (Object.keys(mergeParentData).length) {
                rowData = { ...mergeParentData };
              }

              if (
                rowData &&
                Object.keys(rowData).length &&
                !modalForm?.uri?.[objectCollectionUri as string]?.formValues
              ) {
                updateCellValues(e, rowData);
              } else if (modalForm?.uri?.[objectCollectionUri as string]?.formValues) {
                updateCellValues(e, modalForm?.uri?.[objectCollectionUri as string]?.formValues ?? {}, true);
              }
            };
          }

          if (e.parentType === 'dataRow' && e.caption === 'Override Comment') {
            e.editorOptions.onValueChanged = (data: any) => {
              if (/^[a-zA-Z0-9 ]*$/.test(data.value)) {
                e.component.cellValue(e.row.rowIndex, 'Override Comment', data.value);
              }
            };
          }
        },
        [components.global.selectedRowData, selectedRowData, updateCellValues],
      );

      const dataSourceWithValidators = useMemo(
        () =>
          addValidatorsToDataSource({
            fieldName,
            dataSource,
            externalProps: {
              ...(formProps?.parent && Object.keys(formProps.parent).length
                ? { formProps: formProps }
                : { formProps: _formProps }),
              dataGridRef: gridViewRef,
            },
          }),
        [dataSource, fieldName, formProps],
      );

      const dataSourceWithAPIRules = useMemo(
        () =>
          addAPIRulesToDataSource({
            dataSource: dataSourceWithValidators,
            externalProps: {
              ...(formProps?.parent && Object.keys(formProps?.parent).length
                ? { formProps: formProps }
                : { formProps: _formProps }),
            },
          }),
        [dataSourceWithValidators, formProps],
      );

      const summaryRender = useCallback(() => getSummariesFromDataSource(fieldName), [fieldName]);

      /**
       * @param e - cell edit event
       * @callback Method to handle change in allocation % in GOM grid cells and highlight the footer
       */
      const handleOnCellPrepared = useCallback(
        (e: any) => {
          if (gridEditProperty.edit && e.rowType === 'totalFooter') {
            footerSummaryFields({
              column: e.column.caption,
              e,
              ...(formProps?.parent && Object.keys(formProps?.parent).length
                ? { formProps: formProps }
                : { formProps: _formProps }),
            });
          }
        },
        [gridEditProperty, formProps],
      );

      const getFundList = () => {
        const fundList = dataSource.data.map((item: any) => item.fund);
        const updatedFundList = (gridViewRef.current as any).instance
          .getController('data')
          ._items.map((item: any) => item.data.fund)
          .filter((fund: string | undefined) => fund);
        return updatedFundList?.length ? updatedFundList : fundList;
      };

      const EditObjectCellComponent = useCallback(
        ({ props, column }: any) => {
          const field: FormFieldType = {
            dataType: '',
            formElementType: '',
            defaultValue: '',
            name: '',
            displayName: '',
            searchType: '',
            hideLabel: true,
            required: false,
            readonly: !column.allowEditing,
            textDark: layout === LayoutRegion.BOTTOM ? true : false,
            searchService: SearchService().search,
          };

          switch (column.dataType) {
            case glideDataTypeMap.Object:
              if (!column.lookups) {
                field.dataType = 'object';
                field.formElementType = 'search';
                field.defaultValue = props.data.text;
                if (column?.objectType) {
                  field.searchType = column.objectType;
                }
                const onChange = (e: any) => {
                  props.data.setValue(e.target.value, e.target.name);
                };
                if (column?.dataField === 'fund') {
                  field.required = true;
                }

                return (
                  <SearchInput
                    field={field}
                    onChange={onChange}
                    value={props.data.text}
                    fundList={getFundList()}
                    formGroupChildrenStyle={{
                      width: 'auto',
                      flex: 'unset',
                      minWidth: '120px',
                      marginLeft: '5px',
                      marginTop: '5px',
                    }}
                    formGroupStyle={{
                      marginBottom: '0px',
                    }}
                  />
                );
              }
              return null;
            default:
              return null;
          }
        },
        [getFundList],
      );

      const defaultDxToolbarButtonsActions = useMemo(
        () => ({
          saveButton: () => {
            persistChanges();
          },
        }),
        [persistChanges],
      );
      const getPrimaryActionIcons = useCallback(
        (primaryActions: any) => {
          const buttonsConfig: any = [];
          if (primaryActions && primaryActions.length) {
            primaryActions.map((action: any) => {
              if (action.text.indexOf(ButtonsToExclude) === -1) {
                buttonsConfig.push({
                  name: `${action.text.toLowerCase()}Button`,
                  widget: 'dxButton',
                  options: {
                    icon: buttonIconProps[action.text].icon,
                    hint: action.text,
                    elementAttr: { 'data-testid': `${action.text.toLowerCase()}-btn-order-selection` },
                    onClick: action?.actionFn,
                    disabled: action?.props?.hasOwnProperty('enabled') ? !action?.props?.enabled : false,
                  },
                  location: 'after',
                });
              }
            });
          }
          return buttonsConfig;
        },
        [primaryAction, gridEditProperty],
      );
      const { objectCollectionColumns, GlideObjectManagerOverlay } = useGlideObjectManager({
        schema: dataSource?.schema,
        // objectEditActions,
        // objectEditInspectors,
        gridActions: {
          refresh: onRefresh,
        },
        id: clientViewUri,
      });
      const getToolbarButtons = useCallback((): ToolBarButtonsType => {
        const actionCollectionsConfig = [];
        if (otherActions && otherActions.length && !gridEditProperty.edit) {
          actionCollectionsConfig.push(otherActionsConfig);
        }
        const addAfterButtons = getPrimaryActionIcons(nestedPrimaryActions ?? primaryAction);
        return {
          add: [],
          addAfter: [...addAfterButtons],
          addBefore: [...actionCollectionsConfig],
          remove: [...removeIconsConfig],
        };
      }, [orderCollectionUris, primaryAction, otherActions, nestedPrimaryActions, gridEditProperty]);
      const getToolbarIconsOrder = (actions: any) => {
        return actions ? actions.map((action: any) => `${action.text.toLowerCase()}Button`) : [];
      };
      const getDataGridProperties = useCallback(
        (dataGridProps: any) => {
          dataGridProps = {
            ...dataGridProps,
            customCellRenderKeyProps: {
              ...objectCollectionColumns,
            },
            toolbarIconsOrder: [
              !gridEditProperty.edit && 'otherActionsButtons',
              ...getToolbarIconsOrder(primaryAction),
              'addRowButton',
              'saveButton',
              ...getToolbarIconsOrder(nestedPrimaryActions),
              'exportButton',
              'columnChooserButton',
            ],
            dxDataGridProps: {
              ...dataGridProps.dxDataGridProps,
              onCellClick: (cellData: any) => {
                const results = cellData?.row?.data;
                if (results) {
                  setOrderCollectionUris([results._uri]);
                }
              },
              toolbarButtons: {
                ...getToolbarButtons(),
              },
            },
            gridColumnAction: rowActions?.length > 0 && rowActions,
          };
          return dataGridProps;
        },
        [getToolbarButtons, otherActions, primaryAction, nestedPrimaryActions, gridEditProperty, rowActions],
      );

      let dataGridProps: GridViewProps = useMemo(
        () => ({
          dataGrid: {
            dataSource: {
              schema: dataSourceWithAPIRules.schema,
              data: dataSource?.data,
            },
            summaryRender,
          },
          id: 'data-grid-order-bottom-panel',
          storageKey: fieldName,
          isFilterButtonLeftAligned: true,
          isSearchPanelLeftAligned: isSearchPanelLeftAligned,
          skipKeyId: true,
          dxDataGridProps: {
            noDataText: 'No Data',
            onCellPrepared: handleOnCellPrepared,
            onEditorPreparing,
          },
          EditObjectCellComponent,
          customizeColumns,
          customCellRenderKeyProps: objectColumns,
          overrideGridViewProps: {
            ...gridEditProperty,
            ...overrideGridViewProps,
          },
          layout,
          actionsCollection: !gridEditProperty.edit && otherActions,
          customDxToolbarButtonsActions:
            layout === LayoutRegion.BOTTOM ? customDxToolbarButtonsActions : defaultDxToolbarButtonsActions,
          allowHeaderSearch: layout === LayoutRegion.BOTTOM,
          exportFileName: fieldName?.capitalizeWords('_'),
          showFilterButton: showFilterButton,
          showRefreshButton: showRefreshButton,
        }),
        [
          EditObjectCellComponent,
          dataSource?.data,
          dataSource?.schema,
          fieldName,
          customDxToolbarButtonsActions,
          defaultDxToolbarButtonsActions,
          gridEditProperty,
          handleOnCellPrepared,
          onEditorPreparing,
          summaryRender,
          orderCollectionUris,
          otherActions,
          primaryAction,
          nestedPrimaryActions,
        ],
      );

      dataGridProps = useMemo(() => getDataGridProperties(dataGridProps), [
        fieldName,
        dataGridProps,
        getDataGridProperties,
        otherActions,
        primaryAction,
        nestedPrimaryActions,
      ]);

      return (
        <>
          <QuitGOMDialog />
          <S.Wrapper>
            {loading || !dataSource ? (
              <S.LoadingWrapper>
                <LoadingIconGlide size="extraLarge" show={loading} />
              </S.LoadingWrapper>
            ) : (
              <S.Wrapper data-testid="glide-object-manager" className="glide-object-manager">
                {showHeader && layout === LayoutRegion.POPUP && (
                  <Header
                    onBack={overridePopupGOMProps ? overridePopupGOMProps.onBack : () => {}}
                    title={fieldName}
                    displayType={displayType}
                    handleExpansion={handleExpansion}
                  />
                )}
                <S.Body>
                  <S.Grid>
                    <GridView
                      ref={gridViewRef}
                      {...dataGridProps}
                      onCustomToolBarPreparing={onCustomToolBarPreparing}
                      onRefresh={handleOnRefresh}
                      layout={layout}
                    />
                  </S.Grid>
                </S.Body>
              </S.Wrapper>
            )}
          </S.Wrapper>
          {GlideObjectManagerOverlay}
        </>
      );
    },
  ),
);

const mapStateToProps = (state: RootState): ReduxProps & ComponentProps => ({
  components: selectComponents(state),
  modalForm: selectModalForm(state),
});

export default connect(mapStateToProps)(GlideObjectManagerComponent);
