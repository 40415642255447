import { sortBy } from 'lodash';
import { actionTypes, mutateAsync, requestAsync } from 'redux-query';
import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { ActionArguments, Glide } from 'src/api/queries';
import { ACTION_FORM_OVERRIDE_ACTIONS, ActionPayload, actions } from 'src/reducers/actions';
import {
  ComponentPropsType,
  CurrentObjectInView,
  selectComponents,
  selectGlobalViewComponent,
  selectViewComponent,
  updateComponentAction,
  updateComponentViewAction,
} from 'src/reducers/components';
import { selectedFunds } from 'src/reducers/portfolio';
import { IExecuteActionProps, executeAction } from 'src/utils/action-resolver';
import { glideQuery, glideQuerySelectorViewName } from 'src/api/query';
import { parseDisplayViewData } from 'src/api/queries/display-view';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { selectCVC } from 'src/reducers/tabs';
import {
  MODAL_INSPECTOR,
  selectModalInspectorData,
} from 'src/components/inspectors/glide-object-inspector/modal-inspector';
import { saveInspectorForm, setInspectorFormEdit } from 'src/reducers/inspectorForm.reducer';
import { NotificationsAction, pendingNotificationAction } from 'src/reducers/notifications';
import { RootState } from 'src/reducers';
import { GlideObject } from 'src/models/glide/glideObject';
import { AxiosResponse } from 'axios';
import { IActionArguments } from 'src/models/order/glideActionArguments.api.model';
import { selectActionArguments } from 'src/api/queries/ActionArguments/ActionArguments';
import { resetModalForm, saveModalForm, setModalForm, setModalFormEdit } from 'src/reducers/modalForm.reducer';
import { saveCreditDetailForm, setCreditDetailFormEdit } from 'src/reducers/creditDetailForm.reducer';
import { authSelectors } from '@virtus/common/auth/reducer';
interface ExecuteActionType {
  payload: ActionPayload;
}

export const selectExpandedAction = (state: RootState): GlideObject => state.entities?.expandedAction?.[0];

export function* getActionArguments(actionUri: string) {
  const actionArguments: IActionArguments[] = yield select(ActionArguments.selector);
  if (!actionArguments || !actionArguments.hasOwnProperty(actionUri)) {
    yield putResolve(requestAsync(ActionArguments.get(actionUri)));
  }
}

export const selectExpandedGlobalAction = (state: RootState) => {
  if (state.entities.expandedGlobalActions && state.entities.expandedGlobalActions.length > 0)
    return state.entities.expandedGlobalActions[0];
};

/*
TODO: Rename  to handle action as it does numerous things:
- render action view in GOI if is has a view
- render action in ActionForm if it has argument (render action arguments)
- *execute action* if we're not handling any of the above (action
- test every use cases to avoid regression
*/
export function* actionResolve(dispatchAction: ExecuteActionType): any {
  const { action, actionUri, targetUri, clientViewUri } = dispatchAction.payload;
  // Temporary merge (when dispatchAction is missing, tech debt)
  const _actionUri = action?.uri || actionUri || '';

  if (!_actionUri) {
    console.warn('actionUri missing', dispatchAction.payload);
    return;
  }

  const components: ComponentPropsType = yield select(selectComponents);

  console.info('[dispatchAction.saga] handle dispatchAction payload, dispatchAction:', action);
  console.info('[dispatchAction.saga] actionUri', actionUri);

  if (_actionUri.includes('global_actions')) {
    yield put(resetModalForm(action.data.action));
    yield put(pendingNotificationAction);

    yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/glide/gget',
          // TODO: make more meaningful
          body: { uri: action.data.action, expand_prop: ['actions'] },
          transform: (response: AxiosResponse) => {
            return { expandedAction: response };
          },
          update: {
            expandedAction: (_: any, next: any) => next,
          },
          //Making false because it's not updating uri. By parsing URI bahavior is taken
          cache: false,
        }),
      ),
    );

    const rootAction = yield select(selectExpandedAction);
    const rootActionData = rootAction.data;
    if (rootActionData?.view) {
      // All actions with a view use GOI

      yield putResolve(
        requestAsync(
          glideQuery({
            endpoint: '/glide/display-view/groups',
            body: {
              uri: action.data.action,
              fetch_options: 'workflow_transitions',
            },
            transform: (body: any) => ({ [MODAL_INSPECTOR]: parseDisplayViewData(body) }),
            update: {
              [MODAL_INSPECTOR]: (prev: any, next: any) => ({
                ...prev,
                [action.data.action]: {
                  ...next,
                },
              }),
            },
          }),
        ),
      );
      yield put({ type: NotificationsAction.RESET_NOTIFICATION });
      yield put(
        updateComponentAction(MODAL_INSPECTOR, {
          [action.data.action]: { isVisible: true, visible: true, uri: _actionUri },
        }),
      );
      // yield put(updateComponentAction(MODAL_INSPECTOR, { visible: true, uri: _actionUri }));

      const modalInspectorData = yield select(selectModalInspectorData);
      yield put(setModalForm(modalInspectorData[action.data.action]));
      yield put(setModalFormEdit(true, modalInspectorData[action.data.action]));
    } else if (rootActionData?.arguments) {
      yield call(getActionArguments, rootAction.uri);
      yield put(
        updateComponentAction('actionForm', { visible: true, actionUri: rootAction.uri, target_uri: rootAction.uri }),
      );
    }
  }
  // Those actions are called directly by components but support for the glide action
  // is added there for consistency
  else if (_actionUri.includes('edit_object')) {
    if (components.modalInspector[clientViewUri]?.isVisible) {
      const modalInspectorData = yield select(selectModalInspectorData);
      yield put(setModalFormEdit(true, modalInspectorData[clientViewUri]));
    } else if (components.creditDetailInspector.isVisible) {
      yield put(setCreditDetailFormEdit(true));
    } else {
      // this keyActions is for locking inspector while editing.
      const keyAction = 'edit_object';
      const glideSession = yield select(authSelectors.glideSession);
      const date = new Date().toJSON();
      const getResponse = yield putResolve(
        requestAsync(
          glideQuery({
            endpoint: '/glide/gget',
            options: { method: 'GET' },
            body: { uri: components.actionForm.target_uri },
          }),
        ),
      );
      const inspectorStatus = getResponse.body[0].data?.status;
      if (inspectorStatus?.includes(glideSession.username) || !inspectorStatus) {
        yield putResolve(
          requestAsync(
            glideQuery({
              endpoint: '/gset',
              options: { method: 'POST' },
              body: {
                uri: components.actionForm.target_uri,
                action: keyAction,
                date: date,
              },
            }),
          ),
        );
        yield put(setInspectorFormEdit(true, clientViewUri));
      } else {
        yield put({
          type: NotificationsAction.LOCKED_NOTIFICATION,
          payload: {
            errorMessage: inspectorStatus,
          },
        });
      }
    }
    // components.modalInspector.isVisible ? yield put(setModalFormEdit(true)) : yield put(setInspectorFormEdit(true));
  } else if (_actionUri.includes('saveobject')) {
    if (components.modalInspector.isVisible) {
      yield put(saveModalForm(clientViewUri));
    } else if (components.creditDetailInspector.isVisible) {
      yield put(saveCreditDetailForm());
    } else {
      yield put(saveInspectorForm(clientViewUri));
    }

    // components.modalInspector.isVisible ? yield put(saveModalForm()) : yield put(saveInspectorForm());
  } else if (_actionUri.includes('canceledit')) {
    if (components.modalInspector.isVisible) {
      const modalInspectorData = yield select(selectModalInspectorData);
      yield put(setModalFormEdit(false, modalInspectorData[clientViewUri]));
    } else if (components.creditDetailInspector.isVisible) {
      yield put(setCreditDetailFormEdit(false));
    } else {
      yield put(setInspectorFormEdit(false));
    }
    // components.modalInspector.isVisible ? yield put(setModalFormEdit(false)) : yield put(setInspectorFormEdit(false));
  }

  // populate <ActionForm /> with dispatchAction arguments
  else {
    // Following states are only needed for the rest of the actions.
    // Do not hoist them to avoid to simplify code (those actions should be extracted into dispatch like
    const cvc: ClientViewConfigurationData = yield select(selectCVC, clientViewUri);
    const currentObjectInView =
      clientViewUri === 'global'
        ? yield select(selectGlobalViewComponent, 'currentObjectInView')
        : yield select(selectViewComponent, 'currentObjectInView', cvc.uri);
    const _targetUri = targetUri || currentObjectInView?.uri;
    yield call(getActionArguments, _actionUri);
    const actionArgumentsFromStore = yield select(selectActionArguments);

    if (action?.data?.arguments || actionArgumentsFromStore[_actionUri]?.arguments) {
      let payload = null;
      if (_actionUri === 'instance/actions/add_order_to_scenario') {
        yield putResolve(
          mutateAsync(
            glideQuery({
              endpoint: `/glide/hypo/scenarios`,
              options: { method: 'GET' },
              body: {},
              storeViewName: 'hypoScenarios',
              meta: {
                notification: {
                  [actionTypes.MUTATE_START]: `Fetching hypo scenarios`,
                  [actionTypes.MUTATE_SUCCESS]: `Hypo scenarios are available`,
                },
              },
              // Empty scenarios responses have no body
              transform: (body: any) => ({
                views: {
                  ['hypoScenarios']: {
                    data: body.data?.length > 0 ? JSON.parse(body?.data) : {},
                    schema: body?.schema,
                  },
                },
              }),
            }),
          ),
        );
        const hypoScenarios = yield select(glideQuerySelectorViewName, 'hypoScenarios');

        payload = {
          'instance/argument/add_to_hypo_scenario_target_scenario': {
            lookups:
              hypoScenarios?.data?.length > 0 &&
              hypoScenarios.data.reduce((acc: any, item: any) => {
                if (!acc[item._uri]) {
                  acc[item._uri] = { name: item['Display Name'], value: item._uri };
                }
                return acc;
              }, {}),
          },
        };
      }
      if (_actionUri === 'instance/actions/fund_modeling') {
        const portfolios = yield select(Glide.selector, 'portfolios');
        const cvc: ClientViewConfigurationData = yield select(selectCVC);
        const funds = yield select(selectedFunds, cvc);
        payload = {
          'fields/modeling_portfolio': {
            lookups: (funds ?? portfolios)?.reduce((acc: any, item: { data: any; uri: string }) => {
              if (!acc[item.uri]) {
                acc[item.uri] = { name: item.data.display_name, value: item.uri };
              }
              return acc;
            }, {}),
            defaultValue: funds?.length === 1 && funds[0]?.uri,
          },
          'fields/model_id': {
            lookups: sortBy(portfolios, 'data.display_name')?.reduce((acc, item) => {
              if (!acc[item.uri]) {
                acc[item.uri] = { name: item?.data?.display_name, value: item.uri };
              }
              return acc;
            }, {}),
          },
          primaryActionButton: {
            label: 'Run',
          },
        };
      }
      if (payload) {
        yield putResolve({
          type: ACTION_FORM_OVERRIDE_ACTIONS.SET_ACTION_OVERRIDE_OBJECT,
          payload: { [_actionUri]: payload },
        });
      }
      yield call(getActionArguments, _actionUri);
      yield put(updateComponentAction('actionForm', { visible: true, actionUri: _actionUri, target_uri: _targetUri }));
      // Execute action on the Web Socket
    } else {
      if (
        _actionUri === 'instance/actions/legal_entity_new' ||
        _actionUri === 'instance/actions/new_deal' ||
        _actionUri === 'instance/actions/schedule_new'
      ) {
        yield put(resetModalForm(_actionUri));
        yield put(pendingNotificationAction);

        yield putResolve(
          requestAsync(
            glideQuery({
              endpoint: '/glide/gget',
              // TODO: make more meaningful
              body: { uri: _actionUri, expand_prop: ['actions'] },
              transform: (response: AxiosResponse) => {
                return { expandedAction: response };
              },
              update: {
                expandedAction: (_: any, next: any) => next,
              },
              //Making false because it's not updating uri. By parsing URI bahavior is taken
              cache: false,
            }),
          ),
        );

        yield putResolve(
          requestAsync(
            glideQuery({
              endpoint: '/glide/display-view/groups',
              body: {
                uri: _actionUri,
                fetch_options: 'workflow_transitions',
              },
              transform: (body: any) => ({ [MODAL_INSPECTOR]: parseDisplayViewData(body) }),
              update: {
                [MODAL_INSPECTOR]: (prev: any, next: any) => ({
                  ...prev,
                  [_actionUri]: {
                    ...next,
                  },
                }),
              },
            }),
          ),
        );
        yield put({ type: NotificationsAction.RESET_NOTIFICATION });
        yield put(
          updateComponentAction(MODAL_INSPECTOR, {
            [_actionUri]: { isVisible: true, visible: true, uri: _actionUri },
            modalInspectorObjUri: _actionUri,
          }),
        );
        yield put(
          updateComponentViewAction('currentObjectInView', _actionUri, { objectInView: _actionUri, uri: _actionUri }),
        );
        const modalInspectorData = yield select(selectModalInspectorData);
        yield put(setModalForm(modalInspectorData[_actionUri]));
        yield put(setModalFormEdit(true, modalInspectorData[_actionUri]));
      } else {
        console.info('[dispatchAction] executeAction:', _actionUri);
        const executeActionArguments = {
          action: { uri: _actionUri },
          target_uri: _targetUri,
        };
        yield call(executeAction, executeActionArguments);
      }
    }
  }
  console.info('[dispatchAction] end');
}

export function* executeActionWithArgument(action: ExecuteActionType) {
  const { targetUri, actionArguments, actionUri } = action.payload;
  const cvc: ClientViewConfigurationData = yield select(selectCVC);
  const currentObjectInView: CurrentObjectInView = yield select(selectViewComponent, 'currentObjectInView', cvc.uri);
  //Condition is changed to avoid conflict of target uri data received in payload or from component state.
  // let target_uri = targetUri;
  let target_uri = targetUri || currentObjectInView.uri;

  // It is special case where we need to set target uri with selected model fund uri.
  if (actionUri === 'instance/actions/fund_modeling') {
    target_uri = (actionArguments as any)['instance/argument/modelid'];

    // TODO: As of now setting modelType to 'F'. Remove this once core api is accepting uri reference instead of abbrevations.
    if ((actionArguments as any)['instance/argument/modeltype']) {
      (actionArguments as any)['instance/argument/modeltype'] = 'F';
    }
  }
  const executeActionArguments: IExecuteActionProps = { action: { uri: actionUri, actionArguments }, target_uri };
  yield call(executeAction, executeActionArguments);
}

export function* watchActionResolve() {
  yield takeLatest<any>(actions.action.DISPATCH_ACTION, actionResolve);
}

function* watchExecuteAction() {
  yield takeLatest<any>(actions.action.EXECUTE_ACTION, executeActionWithArgument);
}

export default [watchActionResolve, watchExecuteAction];
