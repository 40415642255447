import { put, takeLatest } from 'redux-saga/effects';
import { TabsAction } from 'src/reducers/tabs';

export interface OpenViewPayload {
  data: { fullPath: string; activeView: string };
}

export enum TabActions {
  OPEN_VIEW = 'OPEN_VIEW',
}

const changeViewAction = (action: OpenViewPayload) => ({
  type: TabsAction.CHANGE_VIEW,
  data: { activeView: action.data.activeView },
});

export function* openViewSaga(action: any) {
  yield put({
    type: TabsAction.OPEN_TAB,
    path: action.data.path,
  });

  yield put(changeViewAction(action));
}

export function* openTabSaga(): any {}

export function* watchOpenView() {
  yield takeLatest<any>(TabActions.OPEN_VIEW, openViewSaga);
}

export function* watchOpenTab() {
  yield takeLatest<any>(TabsAction.OPEN_TAB, openTabSaga);
}

export default [
  watchOpenView,
  // watchOpenTab
];
