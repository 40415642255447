import Tooltip from '@mui/material/Tooltip';
import { ArrowDropDown, Close, Done, SettingsOutlined } from '@mui/icons-material';
import { ConfirmInputButton } from '@virtus/components/Buttons';
import DropdownMenu, { DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import { Layout, SaveIcon } from '@virtus/components/icons';
import { Search } from '@virtus/components/Search/Search';
import React, { useState } from 'react';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { GlideLayoutData } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
import * as S from './Layouts.style';
import { connect } from 'react-redux';
import { RootState } from 'src/reducers';
import { Dispatch } from 'redux';
import { ComponentGridLayout, Components, selectComponents } from 'src/reducers/components';
import { dispatchActions } from 'src/app/store';
import {
  createLayoutAction,
  CreateLayoutActionPayload,
  getSelectedLayoutKey,
  ResetLayoutActionPayload,
  resetLayoutAction,
  setLayoutAction,
  SetLayoutActionParams,
  UpdateActionPayload,
  updateLayoutAction,
} from 'src/sagas/layouts/layouts.saga';
import { ViewBlotter } from 'src/api/queries';
import { View } from 'src/api/queries/display-view';
import { selectCVC } from 'src/reducers/tabs';
import { selectCurrentForm } from 'src/reducers/inspectorForm.reducer';
import { CONFIRMATION_DIALOG } from 'src/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import Box from '@mui/material/Box';

export interface LayoutsTemplateRenderProps {
  clientViewUri: string; // This reference needs to be kept static. Current CVC can't be used as it would create duplicate when
  clientViewData: View; // This reference needs to be kept static. Current CVC can't be used as it would create duplicate when
  clientViewConfiguration: ClientViewConfigurationData; // This reference needs to be kept static. Current CVC can't be used as it would create duplicate when
  onSettingsClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  layoutStyle?: any;
}
export interface LayoutTemplateReduxDispatch {
  createLayout: ({ clientViewUri, name }: CreateLayoutActionPayload) => void;
  resetLayout: ({ clientViewUri, layout }: ResetLayoutActionPayload) => void;
  setLayout: ({ layout, clientViewUri }: SetLayoutActionParams) => void;
  updateLayout: ({ clientViewUri, name, isRenamed, layout }: UpdateActionPayload) => void;
}

const getDropdownText = (nbLayouts: number, activeLayout?: GlideLayoutData) => {
  if (activeLayout?.data?.name) return activeLayout?.data?.name;
  if (nbLayouts && nbLayouts !== 0) return `${nbLayouts} layouts`;
  return 'Layouts';
};

export interface LayoutTemplateReduxProps {
  components: Components;
  clientViewData: View;
  clientViewConfiguration: ClientViewConfigurationData;
  currentInspectorForm: any;
}

type LayoutsTemplateRender = LayoutsTemplateRenderProps & LayoutTemplateReduxProps & LayoutTemplateReduxDispatch;

export const onSearchInput = (clientViewData: View, clientViewUri: string, activeLayout: any) => (value: string) => {
  if (!clientViewData.webLayouts.length) return;
  dispatchActions.components.setView('gridLayout', clientViewUri, {
    filteredLayouts: clientViewData.webLayouts.filter(layout =>
      layout.data.name.toLowerCase().includes(value.toLowerCase()),
    ),
    selectedLayout: activeLayout,
  });
};

export const LayoutsTemplateRender = ({
  clientViewConfiguration,
  clientViewData,
  clientViewUri,
  components,
  createLayout,
  onSettingsClick,
  resetLayout,
  setLayout,
  updateLayout,
  currentInspectorForm,
  layoutStyle,
}: LayoutsTemplateRender) => {
  if (!clientViewUri || !clientViewData) return null;

  const [isCreatingANewLayout, setIsCreatingANewLayout] = useState(false);
  const [newLayoutName, setNewLayoutName] = useState('');
  const [openMenu, setOpenMenu] = useState<any>(null);
  const viewGridLayout: ComponentGridLayout = components.viewComponents[clientViewUri]?.gridLayout;

  const onShowNewLayoutInput = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsCreatingANewLayout(true);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();

    if (event.key === 'Enter') {
      onSaveNewLayout();
    }
    if (event.key === 'Escape') {
      setIsCreatingANewLayout(false);
      setOpenMenu(null);
    }
  };
  const onSearchInputChange = onSearchInput(clientViewData, clientViewUri, viewGridLayout?.selectedLayout);
  const onClearLayoutClick = () =>
    resetLayout({ clientViewUri: clientViewConfiguration.uri, layout: viewGridLayout?.selectedLayout });

  const onCloseNewLayout = () => {
    setIsCreatingANewLayout(false);
    setNewLayoutName('');
  };

  const handleLayout = (layout: GlideLayoutData) => {
    localStorage.setItem(getSelectedLayoutKey(clientViewConfiguration), layout.uri as string);
    setLayout({ layout, clientViewUri: clientViewConfiguration.uri });
  };

  const handleLayoutClick = (layout: GlideLayoutData) => {
    if (currentInspectorForm?.isFormDirty) {
      displayConfirmationDialog(layout);
    } else {
      handleLayout(layout);
    }
  };

  const onSaveChanges = (e: React.SyntheticEvent) => {
    if (viewGridLayout?.selectedLayout && viewGridLayout?.permissions?.isMutable) {
      updateLayout({ clientViewUri: clientViewConfiguration.uri, layout: viewGridLayout?.selectedLayout });
    } else {
      setIsCreatingANewLayout(true);
      setOpenMenu(e.currentTarget);
    }
  };

  const onSaveNewLayoutClick = () => {
    if (!newLayoutName) {
      // TODO: either disable or warn about missing layout name
      return;
    }
    createLayout({ name: newLayoutName, clientViewUri });
    setNewLayoutName('');
    onCloseNewLayout();
  };

  const onSaveNewLayout = () => {
    if (currentInspectorForm?.isFormDirty) {
      displayConfirmationDialog();
    } else {
      onSaveNewLayoutClick();
    }
  };

  const onClickLayout = (layout?: GlideLayoutData) => {
    if (layout) {
      handleLayout(layout);
    } else {
      onSaveNewLayoutClick();
    }
  };

  const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: onClickLayout,
    ...CONFIRMATION_DIALOG,
  });

  const onClose = () => {
    setIsCreatingANewLayout(false);
    setOpenMenu(null);
    dispatchActions.components.updateView('gridLayout', clientViewUri, {
      filteredLayouts: null,
    });
  };

  const onNewTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => setNewLayoutName(e.target.value);

  return (
    <>
      <QuitInspector />
      <S.LayoutButtonWrapper>
        <DropdownMenu
          button={
            <S.LayoutButton data-testid="layout-dropdown" style={layoutStyle}>
              <Layout />
              {getDropdownText(clientViewData.webLayouts.length, viewGridLayout?.selectedLayout)} <ArrowDropDown />
            </S.LayoutButton>
          }
          onClose={onClose}
          customAnchorEl={openMenu}
          fixAnchor={!!openMenu}
        >
          {/* DROPDOWN FILTER */}
          <S.LayoutDropdownMenuItem>
            <Search
              data-testid="layouts-filter-input"
              style={{
                input: { backgroundColor: 'var(--background-search-panel)' },
                icon: { color: 'var(--foreground-search-panel)' },
              }}
              onChange={onSearchInputChange}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </S.LayoutDropdownMenuItem>

          {/* LAYOUTS LIST */}
          <S.LayoutDropdownStyled>
            {(viewGridLayout?.filteredLayouts || clientViewData.webLayouts).map(layout => {
              const isPublicLayout = layout?.data?.permissions?.includes('instance/permissions/readonly');
              return (
                <S.LayoutDropdownMenuItemStyled
                  isPublicLayout={isPublicLayout}
                  title={isPublicLayout ? `Layout shared by ${layout.data.owner.replace('users/', '')}` : ''}
                  active={Boolean(
                    viewGridLayout?.selectedLayout && layout.data.name === viewGridLayout?.selectedLayout.data?.name,
                  )}
                  key={layout.uri}
                  data-testid="dropdown-option"
                  onClick={() => handleLayoutClick(layout)}
                >
                  <DropdownMenuItemText style={{ color: 'var(--foregound-dropdown-item-text)' }}>
                    {layout.data.name}
                  </DropdownMenuItemText>
                </S.LayoutDropdownMenuItemStyled>
              );
            })}
          </S.LayoutDropdownStyled>
          {/* DROPDOWN MENU FOOTER */}
          <S.LayoutDropdownFooterStyled>
            <S.LayoutDropdownMenuItemFooter webLayout={clientViewData.webLayouts.length ? true : false}>
              {isCreatingANewLayout ? (
                <S.LayoutDropdownButtonWrapper isOpen={true}>
                  <StyledInput
                    data-testid="new-layout-input"
                    onChange={onNewTextChanged}
                    onKeyDown={onKeyDown}
                    placeholder="New layout"
                    autoFocus
                    onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  />
                  <ConfirmInputButton
                    disabled={newLayoutName === '' || newLayoutName === 'Default'}
                    onClick={onSaveNewLayout}
                  >
                    <Done style={{ color: 'var(--foreground-text)', fontSize: '16px' }} />
                  </ConfirmInputButton>
                </S.LayoutDropdownButtonWrapper>
              ) : (
                <S.LayoutDropdownButtonWrapper>
                  <S.CreateLayoutButton data-testid="new-layout-btn" onClick={onShowNewLayoutInput}>
                    + Create Layout
                  </S.CreateLayoutButton>
                  <S.IconButton onClick={onSettingsClick} data-testid="layout-manager-btn">
                    <SettingsOutlined style={{ width: '22px', color: 'var(--save-cancle-button-icon)' }} />
                  </S.IconButton>
                </S.LayoutDropdownButtonWrapper>
              )}
            </S.LayoutDropdownMenuItemFooter>
          </S.LayoutDropdownFooterStyled>
        </DropdownMenu>

        <S.SaveCancelBtnWrapper className="save-cancel-btn-wrapper">
          <S.IconButton style={{ padding: '6px' }} onClick={onSaveChanges} data-testid="layoutsave-btn">
            <Tooltip title="Save layout changes">
              <Box>
                <SaveIcon
                  style={{
                    width: '16px',
                    color: 'var(--bgLight)',
                  }}
                />
              </Box>
            </Tooltip>
          </S.IconButton>

          <S.IconButton
            style={{
              padding: '1px',
            }}
            onClick={onClearLayoutClick}
            data-testid="layoutdiscard-btn"
          >
            <Tooltip title="Discard layout changes">
              <Box>
                <Close style={{ fontSize: '24px', color: 'var(--save-cancle-button-icon)' }} />
              </Box>
            </Tooltip>
          </S.IconButton>
        </S.SaveCancelBtnWrapper>
      </S.LayoutButtonWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: { clientViewUri: string }): LayoutTemplateReduxProps => ({
  clientViewData: ViewBlotter.selectClientViewData(state, ownProps.clientViewUri),
  clientViewConfiguration: selectCVC(state, ownProps.clientViewUri),
  components: selectComponents(state),
  currentInspectorForm: selectCurrentForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch): LayoutTemplateReduxDispatch => ({
  createLayout: ({ clientViewUri, name }) => dispatch(createLayoutAction({ clientViewUri, name })),
  resetLayout: ({ clientViewUri, layout }) => dispatch(resetLayoutAction({ clientViewUri, layout })),
  setLayout: ({ layout, clientViewUri }) => dispatch(setLayoutAction({ layout, clientViewUri })),
  updateLayout: ({ clientViewUri, name, isRenamed, layout }) =>
    dispatch(updateLayoutAction({ clientViewUri, name, isRenamed, layout })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutsTemplateRender);
